import React, { Component } from "react";
import { getAuth } from "firebase/auth";
// import { userAccess } from '../../firebase';
import { ref, set, onValue} from "firebase/database";
import { db } from '../../firebase';
import { Table } from 'react-bootstrap';
import $ from 'jquery';
import { heroku } from "../../resources/config"
import './MySubscriptions.css'

class MySubscriptions extends Component {
    constructor(props) {
        super(props);

        this.state = { subs: [], loaded: false };
        //this.unsubscribe = this.unsubscribe.bind(this);
    }

    componentDidMount() {
        const auth = getAuth();
        let user = auth.currentUser;
        let u_id = user.uid;
        this.setState({ userID: u_id });
        let subRef = ref(db, "/users/" + u_id + "/subscriptions");
        let tempSubs = [];
        onValue(subRef, ss => {
            //alert(JSON.stringify(ss.val()));
            if (ss.val()) {
                let keys = Object.keys(ss.val());
                keys.map(test => {
                    //console.log(ss.val()[test]);
                    //cancelFirebase(test);
                    //cancelSubscription(ss.val()[test]);
                    tempSubs.push({ id: ss.val()[test].id, fireID: test, status: ss.val()[test].status, statusDate: ss.val()[test].statusDate, access: ss.val()[test].access });
                    return ""
                });
                //return tempSubs;
            }
        });
        this.setState({subs: tempSubs, loaded: true});
    }

    unsub(sub) {
        this.cancelFirebase(sub.fireID);
        this.cancelSubscription(sub.id);

    }

    cancelFirebase(db_id) {
        const auth = getAuth();
        let user = auth.currentUser;
        let u_id = user.uid;
        let subRef = ref(db, "/users/" + u_id + "/subscriptions/" + db_id + "/status");
        set(subRef, "canceled");
    }

    cancelSubscription(sub_id) {

        $.post(
            heroku + "cancel-subscription",
            { subscriptionId: sub_id },
            function (data) {
                alert(data.details);
            }, 'json'
        ).then((response) => {
            console.log(response);
            alert("cancel subscription success");
        })
            .catch((error) => {
                console.log(error);
                alert("cancel subscription failed");
            });
    }

    render() {

        return (

            <div className="MySubContainer">
                {this.state.loaded &&
                    <div>
                        <h1>Your Current Subsciptions</h1>
                    <Table className="sub-table">
                        <thead>
                            <tr>
                                <th>Subscriptions</th>
                                <th>Status</th>
                                <th>As of</th>
                                <th>Action</th>
                            </tr>
                        </thead>

                        <tbody id="body">
                            {this.state.subs.length > 0 &&
                                this.state.subs.map((aLine) => (
                                    <tr class="subRow">
                                        <td class="subCell">{aLine.access}</td>
                                        {aLine.status !== null && <td class="subCell">{aLine.status}</td>}
                                        {aLine.statusDate !== null && <td class="subCell">{aLine.statusDate}</td>}
                                        {aLine.status === "canceled" ?
                                        <td class="subCell">In order to renew your subscription you must create a new one</td> :
                                        <button className="optomize-button" onClick={() => { this.unsub(aLine) }}>Unsubscribe</button>
                                        }
                                    </tr>
                                ))
                            }
                        </tbody>


                    </Table>
                    </div>
                }
            </div>

        )
    }
}

export default MySubscriptions;