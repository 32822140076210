import "./ForgotPassword.css"
import {getAuth, sendPasswordResetEmail} from "firebase/auth"
import { useNavigate } from "react-router-dom";

export function ForgotPassword(){
    let navigate = useNavigate();


    const submitForgot = (event) =>{
        event.preventDefault()
        const auth = getAuth();
        sendPasswordResetEmail(auth, event.target[0].value, {
            url: 'http://grsports.us/signin'
        }).then(() => {
            console.log("email sent");
            navigate("/signin");
            alert("Email Sent");
        })
        .catch((error) => {
            alert(error.message);
            console.log(error);
        })
    }

    return(
        <div className="forgot-password-container">
            <div className="form-container">
                <form onSubmit={submitForgot}>
                    <div className="text-container">
                        <p className="reset-text">Please enter the email for your account</p>
                    </div>
                    <div className="input-container">
                        <input className="email-forgot" name="email" placeholder="Email"/>
                    </div>
                    <div className="button-container-forgot">
                        <button className="submit-forgot" type="submit">Submit</button>
                    </div>
                </form>
            </div>
        </div>
    )
}