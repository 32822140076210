import React from "react";
import FileUpload from "../file-upload/FileUpload";
// import { ProjectionDownload } from "./ProjectionDownload";
import { SheetInfo } from "./SheetInfo";


export function Admin(){
    return(
        <div>
            <SheetInfo/>
            {/* <ProjectionDownload/> */}
            <FileUpload/>
        </div>
    )
}