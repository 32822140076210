import React from 'react';

import Subscription from './Subscription';
import './Pricing.css'
import { getAuth } from 'firebase/auth';
import DownloadDesktop from './DownloadDesktop.js';
import logo from "../../resources/GRDFSLogo.png"
import { useNavigate } from 'react-router';
import MySubscriptions from './MySubscriptions';

export default function Pricing(){
    const navigate = useNavigate();
        return (
            <div className='pricing-listings'>
                <MySubscriptions/>
                <br></br><br></br><br></br><br></br>
                <DownloadDesktop/>
                <br></br><br></br><br></br><br></br>
                <div>
                    <div className='title-pricing'>
                        <img className="logo-pricing"src={logo} alt="logo"/>
                        <h1 className='title-text'>Grim Reaper Subscription Plans</h1>
                    </div>
                    <div className='pricing-package-div'>
                        <div className="pricing-option">
                            <div className='content-container'>
                                <div className='plan-name'>Weekly</div>
                                <div className='plan-price'>$5</div>
                                <div className='plan-description'>Includes Optimizer, Exclusive Articles, and Live NFL News weekly with 7 day free trial</div>
                                <div className="pricing-button">
                                    {getAuth().currentUser ?
                                    <Subscription
                                        sub_name="DFS Football"
                                        sub_id="price_1Lgr29EkQUfPAPtHmKjQRnkc"
                                        trial={true}
                                        price={500}
                                    />:<a className="subscribe-button" href='/signin'>Sign in to subscribe</a>}
                                </div>
                            </div>
                        </div>

                        <div className="pricing-option">
                            <div className='content-container'>
                                <div className='plan-name'>Monthly</div>
                                <div className='plan-price'>$17</div>
                                <div className='plan-description'>Includes Optimizer, Exclusive Articles, and Live NFL News monthly with 7 day free trial</div>
                                <div className="pricing-button">
                                    {getAuth().currentUser ?
                                    <Subscription
                                        sub_name="DFS Football"
                                        sub_id="price_1LnDxeEkQUfPAPtHwFeVtAo0"
                                        trial={true}
                                        price={1700}
                                    />:<a className="subscribe-button" href='/signin'>Sign in to subscribe</a>}
                                </div>
                            </div>
                        </div>

                        <div className="pricing-option">
                            <div className='content-container'>
                                <div className='plan-name'>Season</div>
                                <div className='plan-price'>$50</div>
                                <div className='plan-description'>Includes Optimizer, Exclusive Articles, and Live NFL News for the season with 7 day free trial</div>
                                <div className="pricing-button">
                                    {getAuth().currentUser ?
                                    <Subscription
                                        sub_name="DFS Football"
                                        sub_id="price_1LnDzXEkQUfPAPtHsraVdQMR"
                                        trial={true}
                                        price={5000}
                                    />:<a className="subscribe-button" href='/signin'>Sign in to subscribe</a>}
                                </div>
                            </div>
                        </div>
                        <div className='pricing-option'>
                            <button onClick={() =>navigate("/home")} className='login-button'>Continue without premium access</button>
                        </div>
                        {/* <div className="pricing-option">
                            <div className='content-container'>
                                <div className='plan-name'>Free</div>
                                <div className='plan-price'>$0</div>
                                <div className='plan-description'>Continue without access to the optimizer, exclusive articles, and live news</div>
                                <button className='login-button reduce-pricing'>Continue</button>
                            </div>
                        </div> */}
                        {/* <div className='pricing-image-div'>
                            <img className='pricing-image' src={price} alt="Pricing" ></img>
                        </div> */}
                    </div>
                </div>

            </div>
        )
    }
