import React, { Component } from 'react';
import './ComingSoon.css';

class ComingSoon extends Component {

    render() {
        return (
            <div>
                <div className='coming-soon-list'>
                <h2>Features coming with upcoming releases</h2>
                <ul>
                    <li>Player Stacking</li>
                    <li>Multiplier Slot Exposure</li>
                    <li>Multiplier Slot Position Override</li>
                    <li>Flex Override Toggle</li>
                    <li>Opposing Player Toggle</li>
                    <li>Ceiling/Floor Values</li>
                    <li>More Custom Projections</li>
                    <li>Safe Matchup Toggle</li>
                    <li>Minimum Projected Points/Value</li>
                    <li>GPP/Cash Toggle</li>
                    <li>No Injured Players Display</li>
                    <li>Expected Starters or Confirmed only</li>
                </ul>
                </div>
            </div>
        )
    }

}

export default ComingSoon;
