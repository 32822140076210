import { initializeApp } from 'firebase/app';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import * as rtdb from "firebase/database";
import {ref, onValue}from "firebase/database";
import {useState, useEffect, useContext, createContext} from 'react';
import { getStorage } from "firebase/storage";


const firebaseConfig = {
    apiKey: "AIzaSyB6Th8y3e4cLctVxWKxnfNlaO-r8JhQekA",
    authDomain: "grdfs-d0aee.firebaseapp.com",
    projectId: "grdfs-d0aee",
    storageBucket: "grdfs-d0aee.appspot.com",
    messagingSenderId: "135733533809",
    appId: "1:135733533809:web:4faa5f094c0e238ef9cfd0",
    measurementId: "G-ENLMDH60SS"
  };

  export const app = initializeApp(firebaseConfig);
  export const db = rtdb.getDatabase(app);
  export const AuthContext = createContext();
  const storage = getStorage(app);
  export default storage;

 export const AuthContextProvider = props => {
  
  const [subs] = useState()
  const [user, setUser] = useState()
  const [error, setError] = useState()


  useEffect(() => {

    const unsubscribe = onAuthStateChanged(getAuth(), setUser, setError)
    
    return () => unsubscribe()

  }, [])
  return <AuthContext.Provider value={{ user, error, subs }} {...props} />

}

export const userAccess = async () => {
        const auth = getAuth();
        let user = auth.currentUser;
        let u_id = user.uid;
        let subRef = ref(db, "/users/" + u_id + "/subscriptions");
        let tempSubs = [];
        onValue(subRef, ss=>{
            //alert(JSON.stringify(ss.val()));
            if(ss.val()){
                let keys = Object.keys(ss.val()); 
                keys.map(test=>{
                  //console.log(ss.val()[test]);
                  //cancelFirebase(test);
                  //cancelSubscription(ss.val()[test]);
                  tempSubs.push({id: ss.val()[test].id, fireID: test, access: ss.val()[test].access});
                  return ""
              });
                //return tempSubs;
            }
        });
        return tempSubs;
}

export const useAuthState = () => {
  const auth = useContext(AuthContext)
  return { ...auth, isAuthenticated: auth.user != null }
}