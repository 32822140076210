import React,{useState} from 'react';

import storage from '../../firebase'; 
import {db} from '../../firebase'; 
import {ref as dbRef, set} from "firebase/database";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage"
import Select from 'react-select';  
import "./FileUpload.css"
function FileUpload() {
    // State to store uploaded file
    const [paid, setPaid] = useState(false);
    const [preview, setPreview] = useState("");
    const [file, setFile] = useState("");
    const [img, setIMG] = useState("");
    const [desc, setDesc] = useState("");
    const [title, setTitle] = useState("");
    // progress
    const [percent, setPercent] = useState(0);
    const [folder, setFolder] = useState("");


    function handleChangePreview(event){
        setPreview(event.target.value);
    }

    function paidContent(){
        let temp = !paid;
        setPaid(temp);
    }
    // Handle file upload event and update state
    function handleChange(event) {
        setFile(event.target.files[0]);
    }

    function handleChangeIMG(event){
        setIMG(event.target.files[0]);
    }

    function handleChangeDescription(event){
        
        setDesc(event.target.value);
    }

    function handleChangeTitle(event){
        setTitle(event.target.value);
    }
 
    function selectedFolder(folder){
        setFolder(folder.value);
    }
    const handleUpload = () => {
        if (!file) {
            alert("Please upload a file first!");
        }else if(!img){
            alert("Please upload an Image first!")
        }
        else if(!folder){
            alert("Please select a folder first!");
        }else{
        const storageRef = ref(storage, `/files/${folder}/${file.name.split('.').slice(0, -1).join('.')}`);
        const imageRef = ref(storage, `/files/images/${file.name.split('.').slice(0, -1).join('.')}`);
        const descRef = dbRef(db, `/articles/${file.name.split('.').slice(0, -1).join('.')}`);
       


        if(paid){
            const today = new Date();
            set(descRef, {description: desc, title: title, exclusive: preview, date: today+""});
        }else{
            const today = new Date();
            set(descRef, {description: desc, title: title, date: today+""});
        }
        
        // progress can be paused and resumed. It also exposes progress updates.
        // Receives the storage reference and the file to upload.

        const uploadTask = uploadBytesResumable(storageRef, file);
        const uploadIMG = uploadBytesResumable(imageRef, img);
        uploadIMG.on(
                "state_changed",
                (err) => console.log(err),
                () => {
                    // download url
                    getDownloadURL(uploadIMG.snapshot.ref).then((url) => {
                        console.log(url);
                    });
                }
            );
        uploadTask.on(
            "state_changed",
            (snapshot) => {
                const percent = Math.round(
                    (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                );
 
                // update progress
                setPercent(percent);
            },
            (err) => console.log(err),
            () => {
                // download url
                getDownloadURL(uploadTask.snapshot.ref).then((url) => {
                    console.log(url);
                });
            }
        );
        }
    };
 
    return (
        <div>
            <h1>Use to upload PDF files to our storage</h1>
            <h1>First choose which folder to upload to, THIS IS VERY IMPORTANT PLEASE BE MINDFUL</h1>
            <h2>Once uploaded you cant go back tell Matt or Yasser if there is an accidental upload</h2>
            
            <Select
                    // create local const and map the set to onClick for each option then add button
                    
                    options = {[
                        {label: "nfl", value:"nfl"},
                        {label: "projections", value:"projections"}
                    ]}
                    onChange={selectedFolder}
                    
            />
            <br></br>
            Use this upload to choose the document in HTML Form
            <input type="file" onChange={handleChange} />
            <br></br>
            Use this upload to choose the image to go with the given article in PNG Form
            <input type="file" onChange={handleChangeIMG} accept="application/png" />
            <br></br>
            Next Please type the title for this article
            <br></br>
            <input id="file-description-box" type="text" onChange={handleChangeTitle}/>
            <br></br>
            Next Please type a short description for this article you are uploading
            <br></br>
            <input id="file-description-box" type="text" onChange={handleChangeDescription}/>
            <br></br>
            <input type="checkbox" onClick={paidContent}></input>
            Check this if this is paid content
            <br></br>
            {paid && <div>
                Type a preview for this paid article or uncheck the above box
                <input id="file-description-box" type="text" onChange={handleChangePreview}/>
            <br></br>
            </div>
            }
            <button onClick={handleUpload}>Upload to Firebase</button>
            <p>{percent} "% done"</p>
        </div>
    );
}
 
  
  export default FileUpload;