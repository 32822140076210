import React, {useEffect, useState} from "react";
import ReactDOM from "react-dom";
import "./GameTimes.css";


export default function GameTimes(props) {
    const [filteredGames, setFilteredGames] = useState([]);

    useEffect(()=>{
        
    },[filteredGames])

    function clickGame(e, teams){
        let team1 = (teams.substring(0, teams.indexOf("@") - 1));
        let team2 = (teams.substring(teams.indexOf("@")+2, teams.length))
        if(filteredGames.includes(team1)){
            let newFiltered = filteredGames.filter(team=> ((team !== team1)&&(team!== team2)))
            setFilteredGames(newFiltered);
            props.returnFunction(newFiltered);

        }else{
            let newFiltered = filteredGames;
            newFiltered.push(team1);
            newFiltered.push(team2);
            setFilteredGames(newFiltered);
            props.returnFunction(newFiltered);
        }
        
        let gameClass = document.getElementById(e.currentTarget.id).className;
        if(gameClass === "gameBox"){
            document.getElementById(e.currentTarget.id).className = "gameBox selected";
        }else{
            document.getElementById(e.currentTarget.id).className = "gameBox";
        }

    }

    return (
        <div className="allGames-container">
            <button id="slideLeft" onClick={()=>{document.getElementById('allGames').scrollLeft -= document.getElementById("gameBox").offsetWidth}}> &#8592; </button>

            <div className="allGames" id="allGames">
                {props.props !== undefined && props.props.map((game, index) => {
                    return (
                        <div className="gameBox" id={`game${index}`} onClick={(e)=>{clickGame(e, game.Teams)}}>
                            <div className="gameBox-time">{game.GameTime}</div>
                            <div className="gameBox-teams">{game.Teams}</div>
                            
                        </div>
                    )
                })}

            </div>

            <button id="slideRight"  onClick={()=>{document.getElementById('allGames').scrollLeft += document.getElementById("gameBox").offsetWidth}}> &#8594; </button>

        </div>


    );
}

const rootElement = document.getElementById("root");
ReactDOM.render(<GameTimes />, rootElement);
