import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import {
  GridContextProvider,
  GridDropZone,
  GridItem,
  swap,
  move
} from "react-grid-dnd";
import "./DragNDrop.css";



export default function DragNDrop(props) {
  const [files, setfiles] = React.useState(props);

  useEffect(() => {
    setfiles(props.props)
  }, [props]);




  function onChange(sourceId, sourceIndex, targetIndex, targetId) {
    if (targetId) {
      const result = move(
        files[sourceId],
        files[targetId],
        sourceIndex,
        targetIndex
      );
      return setfiles({
        ...files,
        [sourceId]: result[0],
        [targetId]: result[1]
      });
    }

    const result = swap(files[sourceId], sourceIndex, targetIndex);
    return setfiles({
      ...files,
      [sourceId]: result
    });
  }


  return (
    <div>

      {files.main !== undefined &&
        <React.Fragment>

          <GridContextProvider onChange={onChange}>
            <div className="container-dnd-container">
              <div className="container-dnd">
                <GridDropZone
                  className="corkboard"
                  id="main"
                  boxesPerRow={2}
                  rowHeight={40}
                >
                  {files.main.map(file => (
                    <GridItem className="grid-item" key={file.name}>
                      <div className="file-file">
                        <div className="file-content">{file.name}</div>
                      </div>
                    </GridItem>
                  ))}
                </GridDropZone>
              </div>
              <div className="container-dnd">
                <div className="dnd-grid">
                  {Object.keys(files).filter((name) => name !== "main").map((playerName) => {
                    return <div className="dnd-dropzones">
                      <div className="dnd-dropzones-items">{playerName}</div>
                      <div className="dnd-dropzones-items">
                        <GridDropZone
                          id={playerName}
                          className="corkboard-players"
                          boxesPerRow={1}
                          rowHeight={20}
                        >
                          {files[playerName].map((file) => (
                            <GridItem className="grid-item-player" key={file.name}>
                              <div className="file-file">
                                <div className="file-content">{file.name}</div>
                              </div>
                            </GridItem>
                          ))}
                        </GridDropZone>
                      </div>
                    </div>

                  })}
                </div>
              </div>
            </div>


          </GridContextProvider>
          <div>
            <button className="confirm-popup-button" onClick={() => { props.returnFunction(files) }}>Confirm Spelling</button>

          </div>
        </React.Fragment>

      }
    </div>


  );
}

const rootElement = document.getElementById("root");
ReactDOM.render(<DragNDrop />, rootElement);
