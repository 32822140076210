// import "./Home.css"
import ArticlesView from '../articles/ArticlesView';
import banner from "../../resources/updatedBanner.jpeg"
import "./Home.css"
import { NewsSlides } from '../news/NewsSlides';

export function Home(props){
    
    return(
        <div className='homeContainer'>
            
            <div className='banner-container'>
                <a className="banner-link" href="/dfs-nfl">
                    <span className="img-span">
                        <img width={"50%"} className='banner' src={banner} alt="banner"></img>
                    </span>
                </a>
            </div>

            <NewsSlides/>

            <div className="stories-container-home">
                Article View
                <div className="mini-articles">
                     <div className="mini-news-display"> 
                        <ArticlesView title={false} bar={false} articles={props.articles.slice(0,5)}/>
                    </div>
                    <div className='all-news-div'>
                        <a href="/read-nfl" className='all-news'><b>Read More</b></a>
                    </div> 
                </div>
            </div>
        </div>
    )
        
    
}