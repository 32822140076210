import React, { useEffect } from "react";
import ReactDOM from "react-dom";

import "./ImportProjections.css";
import { heroku } from "../../resources/config";
import axios from "axios";

import FuzzySearch from "react-fuzzy";

export default function ImportProjections(props) {
    const [players, setPlayers] = React.useState(props);
    const [tempHeaders, setTempHeaders] = React.useState(props);
    const [headers, setHeaders] = React.useState(props);
    const [importJSON, setImportJSON] = React.useState();
    const [importedPlayers, setImportedPlayers] = React.useState(props);
    const [nullPlayers, setNullPlayers] = React.useState([]);
    const [unmappedPlayers, setUnmappedPlayers] = React.useState();
    const [playerMappings, setPlayerMappings] = React.useState();
    const [missingPlayerFilter, setMissingPlayerFilter] = React.useState(false);

    useEffect(() => {
        setPlayers(props.players);
        setTempHeaders(props.headers);
        setImportJSON(props.importedPlayers)
    }, [props]);

    useEffect(() => {
        let temp = [];
        if (tempHeaders.main !== undefined) {
            tempHeaders.main.map((header) => {
                return temp.push(header.name);
            })

            setHeaders(temp);
        }
    }, [tempHeaders])

    useEffect(() => {
    }, [nullPlayers])

    useEffect(() => {
    }, [unmappedPlayers])

    useEffect(() => {
    }, [playerMappings])

    useEffect(()=>{
    }, [importedPlayers])


    function changeHeader(column) {
        //console.log(column);
        var selectBox = document.getElementById(column);
        var selectedValue = selectBox.options[selectBox.selectedIndex].value;
        //console.log(selectedValue);
        let temp = importJSON;
        temp["columnMappings"][column] = selectedValue;
        temp["importMode"] = "Import";
        setImportJSON(temp);
        sendImported();
    }

    function sendImported() {
        axios
            .post(heroku + "import-projections", importJSON)
            .then((response) => {
                //setImportJSON(response.data);
                setImportedPlayers(response.data.players);
                setPlayerMappings(response.data.playerMappings);
                setNullPlayers(Object.keys(response.data.playerMappings).filter(e => response.data.playerMappings[e] == null));
                setUnmappedPlayers(response.data.unmappedPlayers);

            });
    }

    function selectPlayer(selected, id) {

        // let temp1 = playerMappings;
        // temp1[id] = selected.playerName; 
        // setPlayerMappings(temp1);
        // let temp = importJSON;
        // temp.playerMappings = temp1;
        // temp.importMode = "MappingOnly";
        // setImportJSON(temp);
        // sendImported();


        // console.log(selected.playerName);
        // console.log(id);

        let tempMap = importJSON.playerMappings;
        tempMap[id] = selected.playerName;
        let tempJSON = importJSON;
        tempJSON.playerMappings = tempMap;
        // tempJSON["importMode"] = "Import";
        //console.log(tempJSON);
        setImportJSON(tempJSON);
        sendImported();
    }


    //   sendImported() {
    //     axios
    //       .post(heroku + "import-projections", this.state.importProjsJSON)
    //       .then((response) => {
    //         // let temp = {};
    //         let unmappedPlayers = response.data.unmappedPlayers;
    //         let nullPlayers = Object.keys(response.data.playerMappings).filter(
    //           (key) => response.data.playerMappings[key] === null
    //         );
    //         if (nullPlayers.length > 0) {
    //           this.setState({ playerMappings: response.data.playerMappings });
    //           this.nullPlayerMapping(nullPlayers, unmappedPlayers);
    //         } else {
    //           this.setState({ playerMappings: response.data.playerMappings });
    //           this.finishImported();
    //         }
    //       });
    //   }

    return (

        <div className="importProjectionsContainer">
            <div className="importProjections">
                {headers !== null && headers.length > 0 &&
                    <div className="columnMatching">
                        <div className="columnOption">
                            <div className="columnLabel">Name Column: </div>
                            <div className="columnSelect">
                                <select id="PlayerName" onChange={() => changeHeader("PlayerName")}>
                                    <option defaultValue={true} id="PlayerName"></option>
                                    {headers.map((header) => {
                                        return <option id="PlayerName" value={header}>{header}</option>
                                    })}
                                </select>
                            </div>
                        </div>
                        <div className="columnOption">
                            <div className="columnLabel">Team Column: </div>
                            <div className="columnSelect">
                                <select id="TeamName" onChange={() => changeHeader("TeamName")}>
                                    <option defaultValue={true}></option>
                                    {headers.map((header) => {
                                        return <option value={header}>{header}</option>
                                    })}</select>
                            </div>
                        </div>
                        <div className="columnOption">
                            <div className="columnLabel">Projected Points Column:</div>
                            <div className="columnSelect">
                                <select id="ProjectedPoints" onChange={() => changeHeader("ProjectedPoints")}>
                                    <option defaultValue={true}></option>
                                    {headers.map((header) => {
                                        return <option value={header}>{header}</option>
                                    })}</select>
                            </div>
                        </div>
                        <div className="columnOption">
                            <div className="columnLabel">First Name Column: </div>
                            <div className="columnSelect">
                                <select id="FirstName" onChange={() => changeHeader("FirstName")}>
                                    <option defaultValue={true}></option>{headers.map((header) => {
                                        return <option value={header}>{header}</option>
                                    })}</select>
                            </div>
                        </div>
                        <div className="columnOption">
                            <div className="columnLabel">Position Column: </div>
                            <div className="columnSelect">
                                <select id="Position" onChange={() => changeHeader("Position")}>
                                    <option defaultValue={true}></option>

                                    {headers.map((header) => {
                                        return <option value={header}>{header}</option>
                                    })}</select>
                            </div>
                        </div>
                        <div className="columnOption">
                            <div className="columnLabel"> Actual Points Column: </div>
                            <div className="columnSelect">
                                <select id="ActualPoints" onChange={() => changeHeader("ActualPoints")}>
                                    <option defaultValue={true}></option>
                                    {headers.map((header) => {
                                        return <option value={header}>{header}</option>
                                    })}</select>
                            </div>
                        </div>
                        <div className="columnOption">
                            <div className="columnLabel">Last Name Column: </div>
                            <div className="columnSelect">
                                <select id="LastName" onChange={() => changeHeader("LastName")}>
                                    <option defaultValue={true}></option>
                                    {headers.map((header) => {
                                        return <option value={header}>{header}</option>
                                    })}</select>
                            </div>
                        </div>
                        <div className="columnOption">
                            <div className="columnLabel">Salary Column: </div>
                            <div className="columnSelect">
                                <select id="Salary" onChange={() => changeHeader("Salary")}>
                                    <option defaultValue={true}></option>
                                    {headers.map((header) => {
                                        return <option value={header}>{header}</option>
                                    })}</select>
                            </div>
                        </div>
                    </div>
                }
                <div className="filters">
                Display only missing players?
                <input type="checkbox" checked={missingPlayerFilter} onClick={()=>{setMissingPlayerFilter(!missingPlayerFilter)}}></input>

                </div>
                {players.length > 0 &&
                    <div className="playerProjections">
                        <table>
                            <thead>
                                <tr>
                                    <th>Team</th>
                                    <th>Player Name</th>
                                    <th>Pos</th>
                                    <th>Salary</th>
                                    <th>Current Proj</th>
                                    <th>Src Team</th>
                                    <th>Src Name</th>
                                    <th>Src Pos</th>
                                    <th>Src Salary</th>
                                    <th>Src Proj Pts</th>
                                    <th>Src Actual Pts</th>
                                </tr>
                            </thead>
                            <tbody className="players">
                                {players.filter((e)=>(!missingPlayerFilter || (missingPlayerFilter && nullPlayers !== null && nullPlayers.length > 0 && nullPlayers.includes(e.playerId)))).map((aPlayer, index) => {
                                    return <tr>
                                        <td>
                                            {aPlayer.team}
                                        </td>
                                        <td>{aPlayer.name}</td>
                                        <td>{aPlayer.position}</td>
                                        <td>{aPlayer.salary}</td>
                                        <td>{aPlayer.proj}</td>
                                        {importedPlayers.length > 0 && nullPlayers.length > 0 &&

                                            <React.Fragment>

                                                {nullPlayers.includes(aPlayer.playerId) !== true ?
                                                    <React.Fragment>
                                                        <td className="leftImportPlayer">
                                                        {importedPlayers[index].team}
                                                        </td>
                                                        <td>
                                                        {playerMappings[aPlayer.playerId]}
                                                        </td>
                                                        <td>{importedPlayers[index].position}</td>
                                                        <td>{importedPlayers[index].salary}</td>
                                                        <td>{importedPlayers[index].proj}</td>
                                                    </React.Fragment>
                                                    :
                                                    <td className="playerSearchTd" colSpan={6}>
                                                        <div className="playerSearchWrapper">
                                                            {unmappedPlayers &&
                                                                <div className="playerSearch">
                                                                    <FuzzySearch
                                                                        list={unmappedPlayers}
                                                                        keys={['playerName']}
                                                                        width={200}
                                                                        height={100}
                                                                        keyForDisplayName={['playerName']}
                                                                        onSelect={(newSelectedItem) => {
                                                                            // Local state setter defined elsewhere
                                                                            selectPlayer(newSelectedItem, aPlayer.playerId);
                                                                        }}
                                                                    />
                                                                </div>
                                                            }</div></td>}
                                            </React.Fragment>
                                        }
                                    </tr>
                                })}
                            </tbody>
                        </table>
                    </div>
                }
                <button className="optomize-button" onClick={() => { props.returnFunction(importJSON, false) }}>Confirm Projections</button>
                <button className="secondary-button" onClick={() => { props.returnFunction(importJSON, true) }}> Cancel Import</button>
            </div>
        </div>


    );
}

const rootElement = document.getElementById("root");
ReactDOM.render(<ImportProjections />, rootElement);
