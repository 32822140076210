import { useLocation } from "react-router";
import "./News.css";

export function News(){
    const location = useLocation();

    return(
        <div className="story-container">
            <div className="story-content">
                <div className="story-img-container">
                    <img className="preview-img"src={location.state.story.images[0].previewUrl} alt="img"/>
                </div>
                <div className="story-header">
                    <h1>{location.state.story.headline}</h1>
                    <p className="story-byline">By {location.state.story.byline}<span className="written-date">{location.state.story.dateline}</span></p>
                </div>
                <div className="description-container">
                    <pre className="description">{location.state.story.storyText}</pre>
                </div>
            </div>
        </div>
    )
}