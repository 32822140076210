import './App.css';
import {Signin} from './components/account/Signin';
import {Signup} from './components/account/Signup';
import SznNFL from './components/nfl/SznNFL.js';
import MySubscriptions from './components/payment/MySubscriptions.js';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  Outlet,
} from 'react-router-dom';

import React from 'react';
import {ref, getDownloadURL, listAll, getMetadata} from "firebase/storage"

import { Home } from './components/home/Home';
import {getAuth,  onAuthStateChanged} from 'firebase/auth';
// import  PreHome  from './components/home/PreHome';
import {useState, useEffect} from 'react';
import { Loading } from './components/loader/Loading';
import Article from './components/articles/Article';
import ArticlesView from  './components/articles/ArticlesView';
import storage from './firebase';
import {db} from './firebase';
import { Admin } from './components/admin/Admin';
import {ref as dbRef, get}from "firebase/database";
import { ForgotPassword } from './components/account/ForgotPassword';
import Optimizer from './components/nfl/Optimizer';
import { NewsView } from './components/news/NewsView';
import { News } from './components/news/News';
import Pricing from './components/payment/Pricing';
import ComingSoon from './components/nfl/ComingSoon';
import DownloadDesktop from './components/payment/DownloadDesktop';
// import DesktopDownload from './components/articles/desktop/DesktopDownload';



const AuthenticatedRoute = ({ user }) => {
  if (user === undefined) return <Loading />;

  return user
    ? <Outlet />
    : <Navigate to="/signin"/>;
};

const AdminRoute = ({ admin }) => {
  if (admin === undefined) return <Loading />;

  return admin
    ? <Outlet />
    : <Navigate to="/home"/>;
};

const AnyRoute = ({user})=>{
  if (user === undefined) return <Loading />;

  return user
    ? <Outlet/>
    : <Outlet />;
}

const UnauthenticatedRoute = ({ user }) => {
  if (user === undefined) return <Loading />;

  return user
    ? <Navigate to="/home"/>
    : <Outlet />;
};

const SignupUnauthenticatedRoute = ({ user }) => {
  if (user === undefined) return <Loading />;

  return user
    ? <Navigate to="/subscriptions"/>
    : <Outlet />;
};

function App(){
  const [articles, setArticles] = useState([]);
  const [once, setOnce] = useState(false);
  const [user, setUser] = useState();
  const [admin, setAdmin] = useState();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(getAuth(), setUser) 
    return unsubscribe;
  }, []);

  useEffect(()=> {
    if(user){
    let userRef = dbRef(db, "/users/"+user.uid+"/roles/admin");
     //potentially look into difference between onvalue and get
    //may need to use get instead of onvalue for some things 
    get(userRef).then((snapshot) => {
      setAdmin(snapshot.val());
      
    }); 
    }
  }, [admin, user]);


  //move to backend
  const showPDF = () => {
    if(!once){
      const listRef = ref(storage, '/files/nfl');
      listAll(listRef)
      .then((res) => {
        res.items.forEach((itemRef) => {
              getMetadata(itemRef).then((metadata) => {
                let imageRef = ref(storage, '/files/images/'+metadata.name);
                let desc;
                let title;
                let preview;
                let exclusive;
                let date;
                //let imageRef = ref(storage, '/files/images/test');
                let descRef = dbRef(db, `/articles/${metadata.name}`);
                
                get(descRef).then((snapshot) => {
                  if(snapshot.val().exclusive){
                    preview = snapshot.val().exclusive;
                    exclusive = true;
                  }else{
                    exclusive = false;
                  }
                  date = snapshot.val().date;
                  desc = snapshot.val().description;
                  title = snapshot.val().title;
                }); 
                getDownloadURL(ref(storage, imageRef)).then((image)=>{
                  if(title === undefined){
                    title = metadata.name
                  }
                let filename = title.replace(/\s/g, '');
                filename = filename.replace(/’/g, "");
                getDownloadURL(ref(storage, itemRef))
              .then((url) => {
               setArticles((allUrls)=> [...allUrls, {path: title, url_name: filename, source: url, image: image, desc: desc, exclusive: exclusive,
                preview: preview, date: date}]);
               })
               
              .catch((error) => {
          // Handle any errors
              });
              
            })
              });
              
          // All the items under listRef.
          });
          })
    }

  }
  
  useEffect(()=>{
    setOnce(true);
  },[articles])

      return(
        
      <Router>
        {!once ? showPDF() :
      <div className="App">
          <Routes>
          <Route element={<UnauthenticatedRoute user={user} />}>
            <Route exact path="/signin" element={<Signin/>} />
            <Route exact path="/adminSignUp" element={<Signup admin={true}/>}/>
            <Route path='/forgot-password' element={<ForgotPassword/>}/>
          </Route>
          
          <Route element={<AuthenticatedRoute user={user} />}>
          {/* <Route path='/home' element={<Home articles={articles}/>} /> */}
            <Route path='/season-nfl' element={<SznNFL/>} />
            <Route path='/desktop-download' element={<DownloadDesktop/>}/>
            <Route path='/my-subscriptions' element={<MySubscriptions/>} />
            <Route path="/news" element={<NewsView max={100000} bar={true}/>} />
            <Route path="/story" element={<News/>} />
            <Route path='/subscriptions' element={<Pricing/>}/>


            <Route element={<AdminRoute admin={admin} />}>
              <Route exact path="/admin" element={<Admin/>} />
              <Route path='/dfs-nfl' element={<Optimizer sport="NFL"/>} />
              <Route path='/dfs-nba' element={<Optimizer sport="NBA"/>} />
              <Route path='/dfs-mlb' element={<Optimizer sport="MLB"/>} />
              <Route path='/dfs-nfl-optimizer' element={<Optimizer/>} />
            </Route>   
          </Route>

          <Route element={<SignupUnauthenticatedRoute user={user}/>}>
            <Route exact path="/signup" element={<Signup admin={false}/>} />

          </Route>

          <Route element={<AnyRoute user={user}/>}>
              


          </Route>
            <Route exact path="/" element={<Home articles={articles.sort((a,b) => new Date(b.date).getTime() - new Date(a.date).getTime())}/>}/>
            <Route path='/home' element={<Home articles={articles.sort((a,b) => new Date(b.date).getTime() - new Date(a.date).getTime())}/>} />
            {/* <Route path='/pricing' element={<Pricing/>}/> */}
            <Route path='/coming-soon' element={<ComingSoon/>}/>
            <Route path='/game-preview' element={<NewsView max={100000} bar={true} storyTypeId={417} title={"Game Preview"}/>}/>
            <Route path='/read-nfl' element={<ArticlesView title={true} bar={true} articles={articles.sort((a,b) => new Date(b.date).getTime() - new Date(a.date).getTime())}/>} />
            <Route path='/game-recap' element={<NewsView max={100000} bar={true} storyTypeId={418} title={"Game Recap"}/>}/>
            {once ?
              articles.map((article)=> {
                return(<Route path={article.url_name} element={<Article article={article}/>} />);
              })
              :<></>
              
            }
          </Routes>
      </div>
      }
      </Router>
        
      )
  }
export default App;
