import React, { useState } from 'react';
import "./Signin.css"
import {getAuth, signInWithEmailAndPassword} from "firebase/auth";
import { useNavigate } from 'react-router';
import { Loading } from '../loader/Loading';
import logo from '../../resources/GRDFSLogo.png'

var email;
var pw;
export function Signin(){
    const [loading, isLoading] = useState(false);

    const getEmailValue = (event)=>{
        const userValue = event.target.value;
        email = userValue;
        // console.log(fn);
    };
    const getPassValue = (event)=>{
        const userValue = event.target.value;
        pw = userValue;
        // console.log(fn);
    };

    let navigate = useNavigate();
    function handleClick(event){
        event.preventDefault();
        navigate("/signup")
    }


    async function OnFormSubmit(e){
        e.preventDefault();
        const auth = getAuth();
        try{
            isLoading(true);
            await signInWithEmailAndPassword(auth, email, pw)
            isLoading(false);
            navigate(-1);
        }catch(err){
            isLoading(false);
            alert(err)
        }
    }

    if(loading){
        return(
            <Loading/>
        );
    }else{
        return(
            <div>
            <div className='signin-body'>
                <div className="signin-wrapper">
                    <div className='left-side-container'>
                        <div className='logo-wrapper'>
                            <img className='picture' src={logo} alt="logo"/>
                        </div>
                    </div>
                    <div className="right-side-container">
                        <div className="form-container">
                            <form onSubmit={OnFormSubmit}>
                                <div className='inputInfo'>
                                    <input className="email-input" type="text" placeholder="Email" name="email" onChange={getEmailValue} required/>
                                    <input className="password-input" type="password" placeholder="Password" name="password" onChange={getPassValue} required/>
                                </div>
                                <div className='buttonsSignin'>
                                    <button className='login-button'type='submit'>Login</button>
                                    <a className="forgot-password" href="/forgot-password">Forgot Password?</a>
                                    <div className='separator'></div>
                                    <button className="signup-button"onClick={handleClick}>Create Account</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            </div>
        );
    }
}
