import React, { Component } from "react";
import Papa from "papaparse";
// import Table from 'react-bootstrap/Table';
import NumericInput from "react-numeric-input";
import CsvDownloader from "react-csv-downloader";

import "./Optimizer.css";
// import file from './ExampleSlate.csv';
import axios from "axios";
import Select from "react-select";

// import { testlocal as heroku } from "../../resources/config";
import { heroku } from "../../resources/config";

import { Loading } from "../loader/Loading.js";
import { getAuth } from "firebase/auth";
import { ref, onValue, set, get } from "firebase/database";
import { db } from "../../firebase.js";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faBars, faCircleXmark } from "@fortawesome/free-solid-svg-icons";
import Pricing from "../payment/Pricing.js";

import ss1 from "./SS-dfs1.png";
import ss2 from "./SS-dfs2.png";
import ss3 from "./SS-dfs3.png";

import DragNDrop from "./DragNDrop.js";
import ImportProjections from "./ImportProjections.js";
import GameTimes from "./GameTimes.js";

// import { testlocal } from "../../resources/config";

export function NotSubscribed() {
  return (
    <div>
      <Pricing />
    </div>
  );
}

class Optimizer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      today: new Date().toLocaleDateString(),
      players: [],
      lineups: [],
      needPosMLB: [
        {
          id: "p1",
          displayName: "P",
        },
        {
          id: "p2",
          displayName: "P",
        },
        {
          id: "c",
          displayName: "C",
        },
        {
          id: "1b",
          displayName: "1B",
        },
        {
          id: "2b",
          displayName: "2B",
        },
        {
          id: "3b",
          displayName: "3B",
        },
        {
          id: "ss",
          displayName: "SS",
        },
        {
          id: "of1",
          displayName: "OF",
        },
        {
          id: "of2",
          displayName: "OF",
        },
        {
          id: "of3",
          displayName: "OF",
        },
      ],
      needPosNFL: [
        {
          id: "qb",
          displayName: "QB",
        },
        {
          id: "rb1",
          displayName: "RB",
        },
        {
          id: "rb2",
          displayName: "RB",
        },
        {
          id: "wr1",
          displayName: "WR",
        },
        {
          id: "wr2",
          displayName: "WR",
        },
        {
          id: "wr3",
          displayName: "WR",
        },
        {
          id: "te",
          displayName: "TE",
        },
        {
          id: "flex",
          displayName: "FLEX",
        },
        {
          id: "dst",
          displayName: "DST",
        },
      ],
      needPosNBA: [
        {
          id: "pg",
          displayName: "PG",
        },
        {
          id: "sg",
          displayName: "SG",
        },
        {
          id: "sf",
          displayName: "SF",
        },
        {
          id: "pf",
          displayName: "PF",
        },
        {
          id: "c",
          displayName: "C",
        },
        {
          id: "g",
          displayName: "G",
        },
        {
          id: "f",
          displayName: "F",
        },
        {
          id: "util",
          displayName: "UTIL",
        },
      ],
      needPosShowdown: [
        {
          id: "cpt",
          displayName: "CPT",
        },
        {
          id: "flex1",
          displayName: "FLEX",
        },
        {
          id: "flex2",
          displayName: "FLEX",
        },
        {
          id: "flex3",
          displayName: "FLEX",
        },
        {
          id: "flex4",
          displayName: "FLEX",
        },
        {
          id: "flex5",
          displayName: "FLEX",
        },
      ],
      slates: [],
      operators: [],
      selectedOperator: "",
      filteredSlates: [],
      projections: [],
      chosen_slate: "",
      defenses: [],
      num_lines: 1,
      update_num: -1,
      exposure: 100,
      csvData: [],
      classic: true,
      searchTerm: "",
      once: false,
      stopRepeat: false,
      ownership: [],
      ownershipCPT: [],
      oppDef: [],
      loading: true,
      contains: false,
      searchTeamTerm: "",
      playerGameKeys: [],
      consensus: [],
      sidebar: true,
      viewSwitch: true,
      resultsGen: false,
      selectedPos: "",
      player_exposure: [],
      maxSalary: 50000,
      flexPos: [],
      minCPT: 0,
      currentSport: props.sport,
      minTeams: 2,
      maxPlayerPerTeamDefault: 4,
      maxPlayerPerTeam: {},//this is for the advanced settings per team table
      lineupUniqueness: 1,
      maxCombinationExposure: 100,
      slateTeamImages: [],
      slateGameTimes: [],
      stackedPositions: new Set(),
      advancedExpanded: false,
      dfsFantasyGame: "",
      importProjsJSON: "",
      visibleColumnMapping: false,
      needPlayerMapping: [],
      unmappedCsvImport: [],
      playerMappings: [],
      testingPlayerMapping: {},
      userID: "",
      playerExposures: {},
      additionalLineupConstraints: [],
      importHeaders: [],
      filteredExposurePlayer: undefined,
      filteredExposureLength: 1,
      filteredExposurePercentage: [],
      playerIdToPlayer: [],
      hideZeroProjs: false,
      filteredPlayerTeams: [],
      stackingExpanded: false,
      maxPlayerPerTeamExpanded: false,
      defaultMaximumTeamExposure: null,
      playerExposureConstraints: [],
      teamExposureConstraints: [],
      teamLineupConstraints: [],
      importProjectionsShowing: false,
      importOptoJSON: ""
    };
    this.loadProj = this.loadProj.bind(this);
    this.loadSlate = this.loadSlate.bind(this);
    this.optomize = this.optimize.bind(this);
    this.selectSlate = this.selectSlate.bind(this);
    this.selectOperator = this.selectOperator.bind(this);
    this.updateNumLines = this.updateNumLines.bind(this);
    this.updateProjectionNum = this.updateProjectionNum.bind(this);
    this.updateExposure = this.updateExposure.bind(this);
    this.submitSettings = this.submitSettings.bind(this);
    this.search = this.search.bind(this);
    this.searchTeam = this.searchTeam.bind(this);
    this.loadAPIDef = this.loadAPIDef.bind(this);
    this.loadAPIProj = this.loadAPIProj.bind(this);
    this.projectionSort = this.projectionSort.bind(this);
    this.valueSort = this.valueSort.bind(this);
    this.salarySort = this.salarySort.bind(this);
    this.saveProjs = this.saveProjs.bind(this);
    this.importProjs = this.importProjs.bind(this);
    this.toggleColumnMapping = this.toggleColumnMapping.bind(this);
    this.changeImportHeaders = this.changeImportHeaders.bind(this);
    this.changePlayerMapping = this.changePlayerMapping.bind(this);
    this.nullPlayerMapping = this.nullPlayerMapping.bind(this);
    this.csvJSON = this.csvJSON.bind(this);
    this.loadProjs = this.loadProjs.bind(this);
    this.sortPos = this.sortPos.bind(this);
    this.subMessage = this.subMessage.bind(this);
    this.changeMaxSalary = this.changeMaxSalary.bind(this);
    this.changeMinCPT = this.changeMinCPT.bind(this);
    this.updateMinTeams = this.updateMinTeams.bind(this);
    this.updateMaxPlayerPer = this.updateMaxPlayerPer.bind(this);
    this.updateLineupUniqueness = this.updateLineupUniqueness.bind(this);
    this.updateMaxCombinationExposure =
      this.updateMaxCombinationExposure.bind(this);
    this.changeStacking = this.changeStacking.bind(this);
    this.toggleExpanded = this.toggleExpanded.bind(this);
    this.sendImported = this.sendImported.bind(this);
    this.finishImported = this.finishImported.bind(this);
    this.updateExposurePerPlayer = this.updateExposurePerPlayer.bind(this);
    this.setFilteredExposurePercentage = this.setFilteredExposurePercentage.bind(this);
    this.selectedTeamFilter = this.selectedTeamFilter.bind(this);
    this.updateMaxPlayerPerTeamConstraint = this.updateMaxPlayerPerTeamConstraint.bind(this);
    this.loadLineups = this.loadLineups.bind(this);
    this.confirmedProjections = this.confirmedProjections.bind(this);
    this.importOptoFile = this.importOptoFile.bind(this);
    this.downloadOptoFile = this.downloadOptoFile.bind(this);
    this.returnImportOpto = this.returnImportOpto.bind(this);
  }

  optimize() {
    this.setState({ viewSwitch: false, resultsGen: true });
    //console.log(this.state.players);
    //let playerPool = JSON.stringify(this.state.players);
    console.log(this.state.currentSport);
    console.log(this.state.chosen_slate);
    console.log(this.state.dfsFantasyGame);
    let settingsJSON = {
      userId: this.state.userID,
      slateId: this.state.chosen_slate.id,
      dfsFantasyGame: this.state.dfsFantasyGame,
      numberOfLineups: this.state.num_lines,
      minimumLineupUniqueness: this.state.lineupUniqueness,
      maximumSalary: this.state.maxSalary,
      minimumTeamsRepresented: this.state.minTeams,

      defaultMaximumPlayersPerTeam: this.state.maxPlayerPerTeamDefault,
      defaultMaximumPlayerExposure: this.state.exposure,
      defaultMaximumPlayerCombinationExposure:
        this.state.maxCombinationExposure,
      defaultMaximumTeamExposure: this.state.defaultMaximumTeamExposure,
      players: this.state.players,

      playerExposureConstraints: this.state.playerExposureConstraints,
      teamExposureConstraints: this.state.teamExposureConstraints,
      teamLineupConstraints: this.state.teamLineupConstraints,
      additionalLineupConstraints: this.state.additionalLineupConstraints,
    };
    console.log(settingsJSON);
    axios
      .post(
        heroku + "optimize-new",
        settingsJSON
        // + "optimize?dfsFantasyGame=" +
        // this.state.dfsFantasyGame +
        // "&num_lines=" +
        // this.state.num_lines +
        // "&minTeams=" +
        // this.state.minTeams +
        // "&maxPlayerperTeam=" +
        // this.state.maxPlayerPerTeam +
        // "&lineupUniqueness=" +
        // this.state.lineupUniqueness +
        // "&maxCombination=" +
        // this.state.maxCombinationExposure +
        // "&exposure=" +
        // ((this.state.exposure / 100) * this.state.num_lines).toFixed(0) +
        // "&maxSalary=" +
        // this.state.maxSalary +
        // "&minCPT=" +
        // this.state.minCPT,
        // this.state.players
      )
      .then((response) => response.data)
      .then((data) => {
        this.setState({ lineups: data });
        let lineData = [];
        let player_exp = [];
        data.map((aLine) => {
          let line = [];
          aLine.lineup.map((playerId) => {
            let playerData = this.state.playerIdToPlayer[playerId];
            if (player_exp[playerId]) {
              player_exp[playerId].amount =
                player_exp[playerId].amount + 1;
            } else {
              player_exp[playerId] = { amount: 1, player: playerData };
            }
            line.push(playerId);
            return "";
          });
          lineData.push(line);
          return "";
        });
        console.log(lineData);
        this.setState({ csvData: lineData, player_exposure: player_exp });
      });
  }
  subMessage() {
    alert("Subscribe to use our optimizer!");
  }

  loadSlate() {
    if (!this.state.once) {
      // this.loadAPIDef();
      this.setState({ once: true, loading: true });
      let subRef;
      if (this.state.currentSport === "NBA") {
        subRef = ref(db, "/slates/NBA/current/playerPool");
      } else if (this.state.currentSport === "NFL") {
        subRef = ref(db, "/slates/NFL/current/playerPool");
      } else if (this.state.currentSport === "MLB") {
        subRef = ref(db, "/slates/MLB/current/playerPool");
      }

      onValue(subRef, (ss) => {
        //axios.get(heroku + "nfl-dfs-slates").then((response) => {
        let tempOperators = [];
        let tempSlates = [];
        Object.keys(ss.val()).map((data) => {
          //response.data.map((slate) => {
          let slate = ss.val()[data];
          if (slate.DfsSlatePlayers) {
            if (
              tempOperators.filter((e) => e.label === slate.Operator).length ===
              0 &&
              slate.Operator === "DraftKings"
            ) {
              tempOperators.push({
                label: slate.Operator,
                value: slate.Operator,
              });
            }
            tempSlates.push({
              //label: [slate.OperatorName],
              label: [data],
              value: {
                id: slate.SlateID,
                slate_players: slate.DfsSlatePlayers,
                Num_Games: slate.NumberOfGames,
                game_type: slate.OperatorGameType,
                operator: slate.Operator,
              },
            });

            if (slate.Operator === "DraftKings" && data === "Main") {
              let temp = {
                value: {
                  id: slate.SlateId,
                  slate_players: slate.DfsSlatePlayers,
                  Num_Games: slate.NumberOfGames,
                  game_type: slate.OperatorGameType,
                  operator: slate.Operator,
                },
              };
              this.selectSlate(temp);
            }
          }
          return "";
        });
        this.setState({ loading: false });
        this.setState({ slates: tempSlates, operators: tempOperators });
        this.selectOperator({ label: "DraftKings", value: "DraftKings" });
      });
    }
  }

  loadAPIProj() {
    axios
      .get(heroku + "find-nfl-projections?sport=NBA")
      // axios
      //   .get(heroku + "player-projections")
      .then((response) => {
        // let temp = {};
        // let temp2 = {};
        // let temp3 = {};
        let GRProj = {};

        Object.keys(response.data).map((player) => {
          // console.log(response.data[player].GRProjection)
          // temp[player] = response.data[player].FantasyPointsDraftKings;
          // temp2[player] = response.data[player].OpponentPositionRank;
          // temp3[player] = response.data[player].GameKey;
          // GRProj[player] = response.data[player].GRProjection;
          GRProj[player] = response.data[player];
          return "";
        });
        this.setState({
          projections: GRProj,
          // consensus: temp,
          // oppDef: temp2,
          // playerGameKeys: temp3,
        });

        this.loadProj();
      })
      .catch((error) => {
        console.log(error);
      });
  }

  loadAPIDef() {
    axios
      .get(heroku + "nfl-dfs-defenses")
      .then((response) => {
        let temp2 = {};
        response.data.map((player) => {
          temp2[player.Team] = player.FantasyPoints;
          return "";
        });
        this.setState({ defenses: temp2 });
      })
      .catch((error) => {
        console.log(error);
        //alert("cancel subscription failed");
      });
  }

  sortPos(pos) {
    let temp;
    if (pos.target.innerHTML === "ALL") {
      temp = "";
    } else if (pos.target.innerHTML === "FLEX") {
      temp = "WRRBTE";
    } else {
      temp = pos.target.innerHTML;
    }
    this.setState({ selectedPos: temp });
  }

  selectSlate(slate) {
    this.setState({ chosen_slate: slate.value });
    this.loadProj(slate.value);

    // *************
    //NEED TO REPLACE WITH WEBSCRAPE OF OWNERSHIP PROJ

    //***************
    //
    // axios
    //   .get(heroku + "nfl-dfs-ownership-projections?slateID=" + slate.value.id)
    //   .then((response) => {
    //     let temp = {};
    //     let temp2 = {};
    //     response.data.SlateOwnershipProjections.map((player) => {
    //       let id;
    //       if (player.PlayerID === null) {
    //         id = player.FantasyDefensePlayerID;
    //       } else {
    //         id = player.PlayerID;
    //       }
    //       if (player.IsCaptain) {
    //         temp2[id] = player.ProjectedOwnershipPercentage;
    //       } else {
    //         temp[id] = player.ProjectedOwnershipPercentage;
    //       }

    //       return "";
    //     });
    //     this.setState({ ownership: temp, ownershipCPT: temp2 });
    //     this.loadProj();
    //   });
  }

  selectOperator(operator) {
    let tempSlates = [];
    this.state.slates
      .filter((e) => e.value.operator === operator.value)
      .map((value) => {
        tempSlates.push(value);
        return "";
      });
    this.setState({ filteredSlates: tempSlates });
    this.setState({ selectedOperator: operator.value });
  }

  loadProj(slate) {
    let temp = [];
    let temp2 = {};
    let tempPlayerMap = {};


    //     let defMap = {
    //       'Cardinals': 'Arizona Cardinals',
    // 'Falcons': 'Atlanta Falcons',
    // 'Ravens': 'Baltimore Ravens',
    // 'Bills': 'Buffalo Bills',
    // 'Panthers': 'Carolina Panthers',
    // 'Bears': 'Chicago Bears',
    // 'Bengals': 'Cincinnati Bengals',
    // 'Browns': 'Cleveland Browns',
    // 'Cowboys':'Dallas Cowboys',
    // 'Broncos':'Denver Broncos',
    // 'Lions':'Detroit Lions',
    // 'Packers':'Green Bay Packers',
    // 'Texans':'Houston Texans',
    // 'Colts':'Indianapolis Colts',
    // 'Jaguars': 'Jacksonville Jaguars',
    // 'Chiefs':'Kansas City Chiefs',
    // 'Raiders':'Las Vegas Raiders',
    // 'Chargers':'Los Angeles Chargers',
    // 'Rams':'Los Angeles Rams',
    // 'Dolphins':'Miami Dolphins',
    // 'Vikings':'Minnesota Vikings',
    // 'Patriots':'New England Patriots',
    // 'Saints':'New Orleans Saints',
    // 'Giants':'New York Giants',
    // 'Jets':'New York Jets',
    // 'Eagles':'Philadelphia Eagles',
    // 'Steelers':'Pittsburgh Steelers',
    // '49ers':'San Francisco 49ers',
    // 'Seahawks':'Seattle Seahawks',
    // 'Buccaneers':'Tampa Bay Buccaneers',
    // 'Titans':'Tennessee Titans',
    // 'Commanders':'Washington Commanders',
    //     }
    if (slate.game_type !== "Classic") {
      this.setState({ classic: false });
      this.setState({ dfsFantasyGame: (slate.operator.toString() + this.state.currentSport.toString()) +"Showdown" })
    } else {
      this.setState({ dfsFantasyGame: (slate.operator.toString() + this.state.currentSport.toString()) +"Classic"});
    }
    let tempTeams = [];
    let tempGames = [];
    slate.slate_players.map((player) => {
      // let proj;
      let position;
      if (!tempTeams.includes(player.Team)) {
        tempTeams.push(player.Team);
      }
      if (player.HomeTeam !== "true") {
        if (tempGames.filter(e => e.Teams === (player.Team + " @ " + player.Opponent)).length < 1) {
          tempGames.push({ GameTime: player.GameTime, Teams: player.Team + " @ " + player.Opponent, UnformattedTime: player.UnformattedGameTime});
        }
      } else {
        if (tempGames.filter(e => e.Teams === (player.Opponent + " @ " + player.Team)).length < 1) {
          tempGames.push({ GameTime: player.GameTime, Teams: player.Opponent + " @ " + player.Team, UnformattedTime: player.UnformattedGameTime });
        }
      }
      //******************** */
      // Uncomment for sports data
      //********************* */
      // if(this.state.projections[player.OperatorPlayerName]){
      //   proj = this.state.projections[player.OperatorPlayerName];
      //}else
      // if(this.state.projections[defMap[player.OperatorPlayerName]]){
      //   proj = this.state.projections[defMap[player.OperatorPlayerName]];
      // }else{
      //   proj = 0;
      // }
      let rosterPosition;
      position = player.OperatorPosition;

      // if (slate.game_type !== "Classic") {
      //   temp2[player.OperatorPlayerName] = player.OperatorPosition;
      //   rosterPosition = player.OperatorRosterSlots;
      //   if (rosterPosition === "CPT") {
      //     player.projection = (player.projection * 1.5).toFixed(2);
      //   }
      // }

      if (slate.game_type !== "Classic") {
        temp2[player.OperatorPlayerName] = player.OperatorPosition;
        rosterPosition = player.OperatorRosterSlots;
        if (rosterPosition === "CPT") {
          player.projection = (player.projection * 1.5).toFixed(2);
        }
      }else{
        temp2[player.OperatorPlayerName] = player.OperatorPosition;
        let mlbPositions = {
          "SP":"P", "RP":"P", "C":"C", "1B":"1B", "2B":"2B", "3B":"3B", "OF":"OF"
        }
        if(mlbPositions[position]){
          rosterPosition = mlbPositions[position];
          rosterPosition = rosterPosition.split("/");
        }else{
          rosterPosition = position.split("/");
        }
      }
      position = position.split("/");
        // temp2[player.OperatorPlayerName] = player.OperatorPosition;
        // rosterPosition = player.OperatorRosterSlots;
        // if (rosterPosition === "CPT") {
        //   player.projection = (player.projection * 1.5).toFixed(2);
        // }

      // if(player.OperatorPosition === "K"){
      //   proj = this.state.consensus[player.OperatorPlayerName];
      // }else
      // if (this.state.projections[player.OperatorPlayerName]) {
      //   proj = this.state.projections[player.OperatorPlayerName];
      // } else if (player.FantasyDefenseProjectionStatID !== null) {
      //   proj = this.state.defenses[player.Team];
      // } else {
      //   proj = 0;
      // }
      // if (position === "CPT") {
      //   proj = (proj * 1.5).toFixed(2);
      // }
      //rosterPosition: cpt+flex



      ///old
      // let setPlayer = {

      //   name: player.OperatorPlayerName,
      //   position: position,
      //   rosterPosition: rosterPosition,
      //   team: player.Team,
      //   salary: player.OperatorSalary,
      //   proj: player.projection,
      //   playerId: player.playerId,
      //   draftableId: player.draftableId,
      //   def: player.DefenseRank,
      //   headshot: player.headshot,
      //   opposingTeam: player.Opponent,
      //   gameTime: player.GameTime,
      //   homeTeam: player.HomeTeam,
      // };

      let playerExists = (temp.findIndex(e => ((e.name === player.OperatorPlayerName)&&(e.team === player.Team))));
      if (playerExists > -1) {
        let appendPlayer = temp[playerExists];
        let tempRosterPositions = appendPlayer.rosterPositions;
        tempRosterPositions.push(rosterPosition);
        appendPlayer.rosterPositions = tempRosterPositions;
        appendPlayer.playerRoles.push({playerId: player.draftableId, rosterPositions: [rosterPosition], salary: player.OperatorSalary});
        temp[playerExists] = appendPlayer;
        // We know that at least 1 object that matches has been found at the index i
      }else{
      let seperatedName = player.OperatorPlayerName.split(" ");
      let setPlayer = {
        averageFantasyPoints: 0,//Need to find this and add to DB
        firstName: seperatedName[0],
        injury: "",//Need to add this news
        isLocked: false,//Need to add locking players
        lastName: seperatedName[seperatedName.length - 1],
        name: player.OperatorPlayerName,
        positions: position,
        //rosterPosition: rosterPosition,
        //team: player.Team,
        //salary: player.OperatorSalary,
        //proj: player.projection,
        projectedPoints: player.projection,
        playerId: player.playerId,
        salary: player.OperatorSalary,
        team: player.Team,
        //draftableId: player.draftableId,
        //def: player.DefenseRank,
        headshot: player.headshot,
        rosterPositions: [rosterPosition],
        playerRoles:[{playerId: player.draftableId, rosterPositions: [rosterPosition], salary: player.OperatorSalary}],
        //opposingTeam: player.Opponent,
        //gameTime: player.GameTime,
        //homeTeam: player.HomeTeam,
      };


      //This is what setPlayer needs to be adjusted to:
      /*
      AverageFantasyPoints: 24.61, FirstName: "CeeDee",Injury: "", IsLocked: false,
      LastName: "Lamb", Name: "CeeDee Lamb", PlayerRoles: [{…}], Positions: ,['WR'], ProjectedPoints: 22.32,
      RosterPositions: (2) ['WR', 'FLEX'], Salary: 9300, Team: "DAL"
      */
      //NOTE: PlayerId below is draftableID
      //Player Roles:
      /*PlayerId: "32104069"
        RosterPositions: (2) ['WR', 'FLEX']
        Salary: 9300
      */


      temp.push(setPlayer);
      tempPlayerMap[player.draftableId] = setPlayer;
      }
      return "";
    });
    //need tempPlayerMap to have updated points when they change
    console.log(temp);
    this.setState({
      players: temp,
      flexPos: temp2,
      slateTeamImages: tempTeams,
      slateGameTimes: tempGames,
      playerIdToPlayer: tempPlayerMap
    });
  }

  submitSettings() {
    this.updateLineupConstraints();
    this.updateTeamLineupConstraints();
    let checks = true;
    if (this.state.chosen_slate.game_type === "Classic") {
      let size = this.state.chosen_slate.Num_Games * 2;

      if (
        ((this.state.exposure / 100) * this.state.num_lines).toFixed(0) <
        Math.ceil(this.state.num_lines / size)
      ) {
        checks = false;
        alert(
          "Not enough possibilities please increase the value to at least " +
          Math.ceil(
            (
              (this.state.num_lines / size / this.state.num_lines) *
              100
            ).toFixed(0)
          )
        );
      } else {
      }
    } else {
      let size = this.state.players.length / 2;
      if (
        ((this.state.exposure / 100) * this.state.num_lines).toFixed(0) <
        Math.ceil((this.state.num_lines * 5) / size)
      ) {
        checks = false;
        alert(
          "Not enough possibilities please increase the value to at least " +
          Math.ceil(
            (
              ((this.state.num_lines * 5) / size / this.state.num_lines) *
              100
            ).toFixed(0)
          )
        );
      } else {
      }
    }

    if (this.state.maxSalary < 45000) {
      this.setState({ maxSalary: 45000 });
    } else if (this.state.maxSalary > 50000) {
      this.setState({ maxSalary: 50000 });
    }

    if (checks) {
      this.optimize();
    }
  }

  projectionSort() {
    axios
      .post(heroku + "nfl-dfs-sort-projections", this.state.players)
      .then((response) => {
        this.setState({ players: response.data });
      });
  }

  valueSort() {
    axios
      .post(heroku + "nfl-dfs-sort-value", this.state.players)
      .then((response) => {
        this.setState({ players: response.data });
      });
  }

  salarySort() {
    axios
      .post(heroku + "nfl-dfs-sort-salary", this.state.players)
      .then((response) => {
        this.setState({ players: response.data });
      });
  }

  updateNumLines(num) {
    this.setState({ num_lines: num });
  }

  updateProjectionNum(num, aLine) {
    let index = this.state.players.findIndex((player) => player === aLine);
    let players = [...this.state.players];
    let player = { ...players[index] };
    player.proj = num.target.value + "";
    players[index] = player;
    if (this.state.chosen_slate.game_type !== "Classic") {
      let index2 = this.state.players.findIndex(
        (player) => player.name === aLine.name && player.rosterPosition === "CPT"
      );
      let cptPlayer = { ...players[index2] };
      cptPlayer.proj = (num.target.value * 1.5).toFixed(2) + "";
      players[index2] = cptPlayer;
    }
    this.setState({ players: players });
  }

  updateExposurePerPlayer(num, playerID) {
    //updates player exposure if the user changes the desired max exposure
    let temp = this.state.playerExposures;
    temp[playerID] = num.target.value;
    this.setState({ playerExposures: temp });
  }

  updateLineupConstraints() {
    let constraints = this.state.additionalLineupConstraints;
    let pExposures = this.state.playerExposures;
    Object.keys(pExposures).map((key) => {
      let tempObject = {};
      tempObject["type"] = "Players";
      tempObject["playerGroup"] = { players: [key] };
      tempObject["maximumPlayersInLineup"] = 0;
      tempObject["exposurePercentage"] = pExposures[key];
      constraints.push(tempObject);
      return "";
    });
    this.setState({ additionalLineupConstraints: constraints });
  }

  updateTeamLineupConstraints() {
    let constraints = this.state.teamLineupConstraints;
    let playerMaxPerTeam = this.state.maxPlayerPerTeam;
    Object.keys(playerMaxPerTeam).map((key) => {
      let tempObject = {};
      // tempObject["type"] = "Players";
      tempObject["playerGroup"] = { key: key };
      tempObject["maximumPlayersInLineup"] = playerMaxPerTeam[key];
      constraints.push(tempObject)
      return "";
    });
    this.setState({ teamLineupConstraints: constraints });
    console.log(this.state.teamLineupConstraints);
  }

  updateExposure(num) {
    this.setState({ exposure: num });
  }

  search(event) {
    this.setState({ searchTerm: event.target.value });
  }

  searchTeam(event) {
    this.setState({ searchTeamTerm: event.target.value });
  }

  componentDidMount() {
    const auth = getAuth();
    let user = auth.currentUser;
    if (user) {
      let u_id = user.uid;
      this.setState({ userID: u_id });
      let subRef = ref(db, "/users/" + u_id + "/subscriptions");
      onValue(subRef, (ss) => {
        //alert(JSON.stringify(ss.val()));
        if (ss.val()) {
          let keys = Object.keys(ss.val());
          keys.map((test) => {
            //console.log(ss.val()[test]);
            //cancelFirebase(test);
            //cancelSubscription(ss.val()[test]);
            if (ss.val()[test].access === "DFS Football") {
              this.setState({ contains: true, loading: false });
            }
            return "";
          });
          if (this.state.contains === false) {
            this.setState({ loading: false });
          }
          //return tempSubs;
        } else {
          this.setState({ loading: false });
        }
      });
    } else {
      this.setState({ loading: false });
    }
    if (this.state.currentSport === "NBA") {
      this.setState({ needPos: this.state.needPosNBA });
    } else if (this.state.currentSport === "NFL") {
      this.setState({ needPos: this.state.needPosNFL });
    } else if (this.state.currentSport === "MLB") {
      this.setState({ needPos: this.state.needPosMLB });
    }
  }

  toggleColumnMapping() {
    if (this.state.visibleColumnMapping) {
      this.setState({ visibleColumnMapping: false, needPlayerMapping: [] });
    } else {
      this.setState({ visibleColumnMapping: true });
    }
  }
  changeImportHeaders = (players) => {
    let columnName = players.PlayerName[0].name;
    let columnPoints = players.Points[0].name;
    let columnTeam = "";
    if (players["Team(Optional)"][0] !== undefined) {
      columnTeam = players["Team(Optional)"][0].name;
    }

    let temp = this.state.importProjsJSON;
    temp["columnMappings"]["PlayerName"] = columnName;
    temp["columnMappings"]["ProjectedPoints"] = columnPoints;
    temp["columnMappings"]["TeamName"] = columnTeam;
    this.setState({ importProjsJSON: temp });
    this.toggleColumnMapping();
    this.sendImported();
  };

  changePlayerMapping(testingPlayerMapping) {
    // console.log(DragNDrop.getResult());
    let tempMap = this.state.needPlayerMapping;
    Object.keys(this.state.needPlayerMapping).map((key) => {
      if (
        testingPlayerMapping[this.state.needPlayerMapping[key].original][0]
          .name !== null
      ) {
        return (tempMap[key].corrected =
          testingPlayerMapping[
            this.state.needPlayerMapping[key].original
          ][0].name);
      } else {
        return (tempMap[key].corrected = tempMap[key].original);
      }
    });
    this.setState({ needPlayerMapping: tempMap });
    this.toggleColumnMapping();
    this.finishImported();
  }

  confirmedProjections(newImportProjsJSON, cancel) {
    if (!cancel) {
      this.setState({ importProjsJSON: newImportProjsJSON });
      let tempJSON = this.state.importProjsJSON;
      tempJSON.importMode = "Import";
      axios.post(heroku + "import-projections", tempJSON).then((response) => {
        document.getElementById("selectedFile").value = "";
        this.setState({ players: response.data.players, importProjectionsShowing: false });

      });
    } else {
      document.getElementById("selectedFile").value = "";
      this.setState({ importProjsJSON: "", importHeaders: "", importProjectionsShowing: false });
    }
  }


  finishImported() {
    let tempJSON = this.state.importProjsJSON;
    tempJSON.importMode = "Import";
    axios.post(heroku + "import-projections", tempJSON).then((response) => {
      let unmappedPlayers = response.data.unmappedPlayers;
      let nullPlayers = Object.keys(response.data.playerMappings).filter(
        (key) => response.data.playerMappings[key] === null
      );
      if (nullPlayers.length > 0) {
        this.setState({ playerMappings: response.data.playerMappings });
        this.nullPlayerMapping(nullPlayers, unmappedPlayers);
      } else {
        this.setState({ players: response.data.players });
      }
    });
  }

  // finishImported() {
  //   let tempMap = this.state.playerMappings;
  //   Object.keys(this.state.needPlayerMapping).map((key) => {
  //     return (tempMap[key] = this.state.needPlayerMapping[key].corrected);
  //   });
  //   let tempJSON = this.state.importProjsJSON;
  //   tempJSON.playerMappings = tempMap;
  //   tempJSON.importMode = "Import";
  //   axios.post(heroku + "import-projections", tempJSON).then((response) => {
  //     let unmappedPlayers = response.data.unmappedPlayers;
  //     let nullPlayers = Object.keys(response.data.playerMappings).filter(
  //       (key) => response.data.playerMappings[key] === null
  //     );
  //     if (nullPlayers.length > 0) {
  //       this.setState({ playerMappings: response.data.playerMappings });
  //       this.nullPlayerMapping(nullPlayers, unmappedPlayers);
  //     } else {
  //       this.setState({ players: response.data.players });
  //     }
  //   });
  // }

  sendImported() {
    axios
      .post(heroku + "import-projections", this.state.importProjsJSON)
      .then((response) => {
        // let temp = {};
        let unmappedPlayers = response.data.unmappedPlayers;
        let nullPlayers = Object.keys(response.data.playerMappings).filter(
          (key) => response.data.playerMappings[key] === null
        );
        if (nullPlayers.length > 0) {
          this.setState({ playerMappings: response.data.playerMappings });
          this.nullPlayerMapping(nullPlayers, unmappedPlayers);
        } else {
          this.setState({ playerMappings: response.data.playerMappings });
          this.finishImported();
        }
      });
  }

  nullPlayerMapping(nullPlayers, unmappedPlayers) {
    let userSpellPlayers = {};

    let mainPlayers = { "main": [] };

    unmappedPlayers.map((player, index) => {
      return mainPlayers["main"].push({ id: index, name: player.playerName })
    })

    nullPlayers.map((aKey) => {
      let tempPlayer = this.state.players.filter(
        (player) => player.playerId === aKey
      );

      //drag and drop code
      mainPlayers[tempPlayer[0].name] = [];




      return userSpellPlayers[aKey] = { "original": tempPlayer[0].name, "corrected": "" };
      // fullMap[aKey] = tempPlayer[0].name;
      //nullPlayers(aKey) = this.state.players.filter((player=>player.id === aKey));
      // console.log(this.state.players.filter((player)=> player.id === aKey));
    });



    this.setState({ needPlayerMapping: userSpellPlayers, visibleColumnMapping: true, unmappedCsvImport: mainPlayers, testingPlayerMapping: mainPlayers });
  }

  importOptoFile(event) {
    let jsonOptoFile = {};
    var file = event.target.files[0],
      read = new FileReader();

    read.readAsBinaryString(file);

    read.onloadend = (() => {
      jsonOptoFile = JSON.parse(read.result);
      this.setState({ importOptoJSON: jsonOptoFile });
      this.returnImportOpto(jsonOptoFile);
    })

  }


  downloadOptoFile(){
    let file = {};
    file.dailyFantasyGame = this.state.dfsFantasyGame;
    file.numberOfLineups = this.state.num_lines;
    file.minimumTeamsPerLineup = this.state.minTeams;
    file.minimumLineupPlayerDifferences = this.state.lineupUniqueness;
    file.defaultMaximumTeamPlayersPerLineup = this.state.maxPlayerPerTeamDefault;
    file.defaultMaximumPlayerExposure = this.state.exposure;
    let posView = [];
    this.state.needPos.map((pos)=>{
      return posView.push({
        "position" : pos.displayName,
        "isIncludedInOpposingTeamConstraint": false,
        "isExcludedInOpposingTeamConstraint": false,
        "isIncludedInPlayerStack1": false,
        "isIncludedInPlayerStack2": false,
        "isIncludedInPlayerStack3": false
      })
    });
    file.positionViews = posView;
    
    let gameView = [];
    this.state.slateGameTimes.map((game)=>{
      let splitTeams = game.Teams.split(' @ ');
      let homeTeam = splitTeams[1];
      let awayTeam = splitTeams[0];
      let unfilteredGameTime = game.UnformattedTime;
      console.log(unfilteredGameTime);
      //Sun 04/07 @ 07:00 PM ET
      return gameView.push({
        "game":{
          "homeTeam": homeTeam,
          "awayTeam": awayTeam,
          "gameDateTime": unfilteredGameTime
        },
        "awayTeam":{
          "team": awayTeam,
          "isLocked": false
        },
        "homeTeam": {
          "team": homeTeam,
          "isLocked": false
        }
      })
    })
    file.gameViews = gameView;
    let playerview = [];
    this.state.players.map((player)=>{
      return playerview.push({
        "player": player
      }
      )
    })
    file.playerViews = playerview;
    file.playerCombinationViews = [];
    file.lineupGenerationSpecifications = [];
    let tempLineups = [];
    if(this.state.csvData.length !== undefined && this.state.csvData.length > 0){
      this.state.csvData.map((line)=>
      {
        let oneLine = {
          isSelected: true,
          players: line
        }
        tempLineups.push(oneLine);
        return oneLine;
      }
      )
    }
    file.sessionLineups = tempLineups;
    var dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(file, null, '\t'));
    var downloadAnchorNode = document.createElement('a');
    downloadAnchorNode.setAttribute("href", dataStr);
    downloadAnchorNode.setAttribute("download", "testFile.opto");
    document.body.appendChild(downloadAnchorNode); // required for firefox
    downloadAnchorNode.click();
    downloadAnchorNode.remove();
  }

  returnImportOpto(file) {
    let tempSettings = {};
    //Finished
    //tempSettings.DailyFantasyGame = file.DailyFantasyGame;
    //tempSettings.NumberOfLineups = file.NumberOfLineups;
    //tempSettings.MinimumTeamsPerLineup = file.MinimumTeamsPerLineup;
    //tempSettings.MinimumLineupPlayerDifferences = file.MinimumLineupPlayerDifferences;
    //tempSettings.DefaultMaximumTeamPlayersPerLineup = file.DefaultMaximumTeamPlayersPerLineup;
    //tempSettings.DefaultMaximumPlayerExposure = file.DefaultMaximumPlayerExposure;

    tempSettings.positionViews = file.positionViews;
    //****Need to Add*********
    // IsExcludedInOpposingTeamConstraint
    // IsIncludedInOpposingTeamConstraint
    // *******Not necessary to add********
    // IsIncludedInPlayerStack1
    // IsIncludedInPlayerStack2
    // IsIncludedInPlayerStack3
    //************************************
    tempSettings.gameViews = file.gameViews;//Need to loop through and checked for Locked Teams

    tempSettings.playerViews = file.playerViews;//Need to adjust the way Player Object is to be uniform with backend
    let tempPlayers = [];
    file.playerViews.map((players)=>{
      let player = players.player;
      let setPlayer = {
        averageFantasyPoints: player.averageFantasyPoints,//Need to find this and add to DB
        firstName: player.firstName,
        injury: player.injury,//Need to add this news
        isLocked: player.isLocked,//Need to add locking players
        lastName: player.lastName,
        name: player.name,
        playerRoles: player.playerRoles,
        positions: player.positions,
        //rosterPosition: rosterPosition,
        //team: player.Team,
        //salary: player.OperatorSalary,
        //proj: player.projection,
        projectedPoints: player.projectedPoints,
        //playerId: player.playerId,
        salary: player.salary,
        team: player.team,
        //draftableId: player.draftableId,
        //def: player.DefenseRank,
        //headshot: player.headshot,
        //opposingTeam: player.Opponent,
        //gameTime: player.GameTime,
        //homeTeam: player.HomeTeam,
      };
      tempPlayers.push(setPlayer);
      return "";
    })
    
    

    tempSettings.playerCombinationViews = file.playerCombinationViews;

    tempSettings.sessionLineups = file.sessionLineups;
    let selectedLineups = file.sessionLineups.filter(e => e.isSelected !== undefined && e.isSelected === true);
    //this chunk of code is just so I dont have to change the humongous block of code to display exposure on FE 
    //and also to trigger that chunk of code with player_exposure
    let csvPlayers = [];
    selectedLineups.map(e => csvPlayers.push(e.players));
    let player_exp = [];
    csvPlayers.map((playerId) => {
      let playerData = this.state.playerIdToPlayer[playerId];
      if (player_exp[playerId]) {
        player_exp[playerId].amount =
          player_exp[playerId].amount + 1;
      } else {
        player_exp[playerId] = { amount: 1, player: playerData };
      }
      return "";
    });
    //****When the back end is ready for ALL generated Lines
    //let generatedLineups = file.SessionLineups;

    //viewSwitch : false in the set State switches view (for now), would like an option at least to go back and forth between results. A button at least
    console.log(tempPlayers);
    this.setState({
      num_lines: file.numberOfLineups, dfsFantasyGame: file.dailyFantasyGame, minTeams: file.minimumTeamsPerLineup,
      lineupUniqueness: file.minimumLineupPlayerDifferences, maxPlayerPerTeamDefault: file.defaultMaximumTeamPlayersPerLineup,
      exposure: file.defaultMaximumPlayerExposure, csvData: csvPlayers, player_exposure: player_exp, players: tempPlayers
    });
  }

  importProjs(event) {
    console.log("working");
    var commonConfig = { delimiter: "," };
    let jsonFile = {};
    //this.csvJSON(event.target.files[0]);

    Papa.parse(event.target.files[0], {
      ...commonConfig,
      download: false,
      complete: (result) => {
        console.log(result.data);
        jsonFile = this.csvJSON(result.data);

        var JSONimport = {
          dfsFantasyGame: this.state.dfsFantasyGame,
          importMode: "MappingOnly",
          columnMappings: {
            ProjectedPoints: "Fpts",
            PlayerName: "Name",
          },
          players: this.state.players,
          csvPlayerData: jsonFile,
          playerMappings: {},
        };
        this.setState({ importProjsJSON: JSONimport, importProjectionsShowing: true });
        //this.toggleColumnMapping();
      },
    });
  }

  //var csv is the CSV file with headers
  csvJSON(csv) {
    let result = "";
    let once = true;
    let headers = [];
    csv.map((row) => {
      row.map((data, index) => {
        if (once) {
          headers.push({ id: index, name: data });
        }
        return result += data + ",";
      })
      if (once) {
        this.setState({ importHeaders: { main: headers, PlayerName: [], Points: [], "Team(Optional)": [] } });
        once = false;
      }
      result = result.substring(0, result.length - 1);
      return (result += "\n");
    });
    result = result.substring(0, result.length - 1);
    return result;
  }

  saveProjs() {
    const auth = getAuth();
    let user = auth.currentUser;
    let u_id = user.uid;
    let projsRef = ref(
      db,
      "/users/" + u_id + "/projections/" + this.state.chosen_slate.id
    );
    set(projsRef, this.state.players);
    alert("We have saved your projections for this slate");
  }
  changeMaxSalary(num) {
    this.setState({ maxSalary: num });
  }
  changeMinCPT(num) {
    this.setState({ minCPT: num });
  }

  loadProjs() {
    const auth = getAuth();
    let user = auth.currentUser;
    let u_id = user.uid;
    let projsRef = ref(
      db,
      "/users/" + u_id + "/projections/" + this.state.chosen_slate.id
    );
    get(projsRef).then((snapshot) => {
      if (snapshot.exists()) {
        this.setState({ players: snapshot.val() });
      } else {
        alert("Sorry, we dont have any of your projections on file");
      }
    });
  }

  updateMinTeams(num) {
    this.setState({ minTeams: num });
  }

  updateMaxPlayerPer(num) {
    this.setState({ maxPlayerPerTeamDefault: num });
  }

  updateLineupUniqueness(num) {
    this.setState({ lineupUniqueness: num });
  }

  updateMaxCombinationExposure(num) {
    this.setState({ maxCombinationExposure: num });
  }

  changeStacking(e) {
    let temp = this.state.stackedPositions;
    if (temp.has(e.target.name)) {
      temp.delete(e.target.name);
    } else {
      temp.add(e.target.name);
    }
    this.setState({ stackedPositions: temp });
    // console.log(e.target.name);
  }

  toggleExpanded() {
    this.setState({ advancedExpanded: !this.state.advancedExpanded })
  }


  getMatchedPlayers = (players) => {
    this.changePlayerMapping(players);
  };

  setFilteredExposurePercentage(playerId) {
    let player_exp = [];
    let filterSize = this.state.lineups.filter(e => e.lineup.some(i => i.includes(playerId))).length;
    this.state.lineups.filter(e => e.lineup.some(i => i.includes(playerId))).map((aLine) => {
      aLine.lineup.map((player) => {
        if (player !== playerId) {
          if (player_exp[player]) {
            player_exp[player].amount =
              player_exp[player].amount + 1;
          } else {
            player_exp[player] = { amount: 1, player: this.state.playerIdToPlayer[player] };
          }
        }
        return "";
      });
      return "";
    });
    this.setState({ filteredExposurePercentage: player_exp, filteredExposurePlayer: this.state.playerIdToPlayer[playerId], filteredExposureLength: filterSize });
  }

  selectedTeamFilter(teams) {
    this.setState({ filteredPlayerTeams: teams });
  }


  updateMaxPlayerPerTeamConstraint(team, maxNum) {
    console.log(team + ": " + maxNum);
    let temp = this.state.maxPlayerPerTeam;
    temp[team] = maxNum;
    this.setState({ maxPlayerPerTeam: temp });
  }

  loadLineups(event) {
    var commonConfig = { delimiter: "," };
    Papa.parse(event.target.files[0], {
      ...commonConfig,
      download: false,
      complete: (result) => {
        let parsedLines = this.parseLineups(result.data);
        this.setState({ lineups: parsedLines })
        console.log("working");
        console.log(this.state.lineups);
      },
    });

  }

  parseLineups(csv) {
    let result = [];
    let skipFirstRow = true;
    console.log(csv);
    csv.map((row) => {
      if (skipFirstRow) {
        skipFirstRow = false;
      } else {
        let temp = [];
        row.map((data) => {
          return temp.push(data);
        });
        result.push(temp);
      } return ""
    });
    return result;
  }


  render() {
    return (
      <div>
        {this.state.loading ? (
          <div>
            <Loading />
            {this.loadSlate()}
          </div>
        ) : (
          <div>
            <div className="optomizer-wrapper">
              {/* <button onClick={this.loadSlate}>Refresh Slates</button> */}

              <div id="opto">
                {
                  this.state.importProjectionsShowing ?
                    <ImportProjections players={this.state.players} importedPlayers={this.state.importProjsJSON} headers={this.state.importHeaders} returnFunction={this.confirmedProjections} /> : <></>
                }
                {/* The following div only shows for importProjs() in order for user to change header columns*/}
                {this.state.visibleColumnMapping &&
                  this.state.needPlayerMapping.length === 0 && (
                    <div className="import-Popup-container">
                      <div className="import-Popup">
                        <h3>
                          Match your imported column headers to help find
                          players
                        </h3>
                        {/* <div>
                        What is the column header for a player's name:
                        <input type="text" id="import-proj-name" placeholder="Name"></input>
                        <br></br>
                        What is the column header for a player's projected points:
                        <input type="text" id="import-proj-projs" placeholder="Fantasy Points"></input>
                        <br></br>
                        <button className="login-button" onClick={() => { this.changeImportHeaders() }}>Confirm Column Headers</button>
                      </div> */}

                        <DragNDrop props={this.state.importHeaders} returnFunction={this.changeImportHeaders} />

                        <div>
                          <button
                            className="login-button cancel"
                            onClick={() => {
                              document.getElementById("selectedFile").value =
                                "";
                              this.toggleColumnMapping();
                            }}
                          >
                            Cancel Import Projections
                          </button>
                        </div>
                      </div>
                    </div>
                  )}

                {this.state.visibleColumnMapping &&
                  this.state.needPlayerMapping.length !== 0 && (
                    <div className="import-Popup-container">
                      <div className="import-Popup">
                        <h3>
                          We cant find the following players in your projections
                          can you correct these players spelling in your
                          projection sheet
                        </h3>

                        <DragNDrop
                          props={this.state.testingPlayerMapping}
                          returnFunction={this.getMatchedPlayers}
                        />
                        {/* {
                        Object.keys(this.state.needPlayerMapping).slice(0, 5).map((aKey) => {
                          return (
                            <div>

                              {this.state.needPlayerMapping[aKey].original}



                              <input type="text" id={"PlayerMapping:" + this.state.needPlayerMapping[aKey].original} ></input>
                            </div>
                          )
                        })
                      } */}
                        {
                          //add another sortable list with null players and match array index
                        }

                        {/* <div>
                        <button className="login-button" onClick={() => { this.changePlayerMapping() }}>Confirm Spelling</button>
                      </div> */}

                        <div>
                          <button
                            className="login-button cancel"
                            onClick={() => {
                              document.getElementById("selectedFile").value =
                                "";
                              this.toggleColumnMapping();
                            }}
                          >
                            Cancel Import Projections
                          </button>
                        </div>
                      </div>
                    </div>
                  )}

                <div className="sort-buttons">
                  <div className="selection-div">
                    <div className="dropdown-selection">
                      <Select
                        id="operators"
                        options={this.state.operators}
                        defaultValue={{
                          label: "DraftKings",
                          value: "DraftKings",
                        }}
                        onChange={this.selectOperator}
                      />
                    </div>
                    <div className="dropdown-selection">
                      <Select
                        id="slate"
                        // create local const and map the set to onClick for each option then add button
                        options={this.state.filteredSlates}
                        onChange={this.selectSlate}
                        defaultValue={{ label: "Main", value: "Main" }}
                      />
                    </div>
                  </div>

                  {this.state.slateGameTimes.length !== 0 && (
                    <GameTimes props={this.state.slateGameTimes} returnFunction={this.selectedTeamFilter} />
                  )}
                  <div className="basic-settings">
                    <div className="numeric-inputs-basic">
                      <div className="numeric-input-div">
                        Lines Generated:
                        <NumericInput
                          // eslint-disable-next-line react/style-prop-object
                          style={false}
                          className="num-input"
                          onChange={this.updateNumLines}
                          precision={0}
                          value={this.state.num_lines}
                          min={1}
                          max={150}
                          snap
                        />
                      </div>
                      <div className="numeric-input-div">
                        Max Salary:
                        <NumericInput
                          // eslint-disable-next-line react/style-prop-object
                          style={false}
                          className="num-input"
                          onChange={this.changeMaxSalary}
                          precision={0}
                          value={this.state.maxSalary}
                          min={45000}
                          max={50000}
                          snap
                        />
                      </div>
                    </div>
                  </div>
                  <div className="projection-button-divs">
                    {/* <button onClick={()=>{this.setState({importProjectionsShowing: true})}}>Test Import */}

                    {/* </button> */}
                    <button
                      className="optomize-button"
                      onClick={
                        this.state.contains
                          ? this.submitSettings
                          : this.subMessage
                      }
                    >
                      Optimize my lineups
                    </button>
                    <input
                      type="file"
                      id="selectedFile"
                      style={{ display: "none" }}
                      onChange={(e) => { this.importProjs(e) }}
                    ></input>
                    <input
                      type="button"
                      className="secondary-button"
                      value="Import external projection"
                      onClick={() => {
                        document.getElementById("selectedFile").click();
                      }}
                    />
                    <input
                      type="file"
                      id="selectedOptoFile"
                      style={{ display: "none" }}
                      onChange={(e) => { this.importOptoFile(e) }}
                      accept=".opto"
                    ></input>
                    <input
                      type="button"
                      className="secondary-button"
                      value="Continue Progress with Opto File"
                      onClick={() => {
                        document.getElementById("selectedOptoFile").click();
                      }}
                    />
                    <input
                      type="button"
                      className="secondary-button"
                      value="Save Progress"
                      onClick={() => {
                        this.downloadOptoFile();
                      }}
                    />
                    <button
                      className="secondary-button"
                      onClick={this.saveProjs}
                    >
                      Save my projections
                    </button>
                    <button
                      className="secondary-button"
                      onClick={this.loadProjs}
                    >
                      Load previously edited projections
                    </button>
                    <input
                      type="file"
                      id="selectedLineupsFile"
                      style={{ display: "none" }}
                      onChange={this.loadLineups}
                    ></input>
                    <input
                      type="button"
                      className="secondary-button"
                      value="Import Lineups"
                      onClick={() => {
                        document.getElementById("selectedLineupsFile").click();
                      }}
                    />

                  </div>
                </div>
                <div className="advanced-settings">
                  <button
                    className="collapse-trigger"
                    onClick={this.toggleExpanded}
                  >
                    <h1>
                      {this.state.advancedExpanded
                        ? "- Advanced Settings"
                        : "+ Advanced Settings"}
                    </h1>
                  </button>
                  {this.state.advancedExpanded && (
                    <div className="optomize-div collapse-content">
                      <div className="stacking">
                        <table className="stacking-table">
                          <thead>
                            <th>Position</th>
                            <th>Is Stacked?</th>
                          </thead>
                          <tbody>
                            {this.state.needPos.map((aPos) => {
                              return (
                                <tr>
                                  <td className="stacking-cell">
                                    {aPos.displayName}
                                  </td>
                                  <td className="stacking-cell">
                                    <input
                                      type={"checkbox"}
                                      name={aPos.displayName}
                                      onClick={this.changeStacking}
                                    ></input>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                      <div className="settings-optomizer">
                        <div className="numeric-input-div">
                          Lineup Uniqueness(Min Player Difference):
                          <NumericInput
                            // eslint-disable-next-line react/style-prop-object
                            style={false}
                            className="num-input"
                            onChange={this.updateLineupUniqueness} //add method to change minimum teams per lineup
                            precision={0}
                            value={this.state.lineupUniqueness} //add minimum teams var to state
                            min={1}
                            max={9}
                            snap
                          />
                        </div>

                        <div className="numeric-input-div">
                          Minimum Teams per Lineup:
                          <NumericInput
                            // eslint-disable-next-line react/style-prop-object
                            style={false}
                            className="num-input"
                            onChange={this.updateMinTeams} //add method to change minimum teams per lineup
                            precision={0}
                            value={this.state.minTeams} //add minimum teams var to state
                            min={1}
                            max={150}
                            snap
                          />
                        </div>

                        <div className="numeric-input-div">
                          Maximum Players Per Team:
                          <NumericInput
                            // eslint-disable-next-line react/style-prop-object
                            style={false}
                            className="num-input"
                            onChange={this.updateMaxPlayerPer}
                            precision={0}
                            value={this.state.maxPlayerPerTeamDefault}
                            min={1}
                            max={100}
                            snap
                          />
                        </div>

                        <div className="numeric-input-div">
                          Max Player Combination Exposure(%):
                          <NumericInput
                            // eslint-disable-next-line react/style-prop-object
                            style={false}
                            className="num-input"
                            onChange={this.updateMaxCombinationExposure}
                            precision={0}
                            value={this.state.maxCombinationExposure + "%"}
                            min={1}
                            max={100}
                            snap
                          />
                        </div>

                        <div className="numeric-input-div">
                          Max Player Appearance(%):
                          <NumericInput
                            // eslint-disable-next-line react/style-prop-object
                            style={false}
                            className="num-input"
                            onChange={this.updateExposure}
                            precision={0}
                            value={this.state.exposure + "%"}
                            min={1}
                            max={100}
                            snap
                          />
                        </div>
                        <div className="slider-input">
                          Hide players with 0 projected points:
                          <label class="switch">
                            <input type="checkbox" checked={this.state.hideZeroProjs}></input>
                            <span class="slider round" onClick={() => { this.setState({ hideZeroProjs: !this.state.hideZeroProjs }) }}></span>
                          </label>

                        </div>

                        {this.state.chosen_slate.game_type !== "Classic" && (
                          <div>
                            <div className="numeric-input-div">
                              Min Salary for CPT:
                              <NumericInput
                                // eslint-disable-next-line react/style-prop-object
                                style={false}
                                className="num-input"
                                onChange={this.changeMinCPT}
                                precision={0}
                                value={this.state.minCPT}
                                min={0}
                                max={20000}
                                snap
                              />
                            </div>
                          </div>
                        )}

                        {/* <button className="apply-settings" onClick={this.submitSettings}>Apply Settings</button> */}
                      </div>
                      {this.state.slateTeamImages.length > 0 ? (

                        <div className="team-cap-table">
                          <table>
                            <thead>
                              <th>Team</th>
                              <th>Max Player</th>
                            </thead>
                            <tbody>
                              {this.state.slateTeamImages.map((team) => {
                                return (
                                  <tr className="player-count-team-row">
                                    <td className="player-count-team">
                                      <div className="player-count-team-image-container">
                                        {this.currentSport !== "NBA" && (
                                          <div>
                                            <h2>{team}</h2>
                                          </div>
                                        )}
                                        {this.currentSport === "NBA" && (
                                          <img
                                            className="player-count-team-image"
                                            src={require(`../../resources/${this.state.currentSport}logos/${this.state.currentSport}_${team}.png`)}
                                            alt="filler"
                                          />
                                        )}
                                      </div>
                                    </td>
                                    <td classname="player-count-team-num">
                                      <div className="player-count-team-num-div">
                                        <NumericInput
                                          // eslint-disable-next-line react/style-prop-object
                                          style={false}
                                          className="player-count-team-num-input"
                                          onChange={(num) =>
                                            this.updateMaxPlayerPerTeamConstraint(team, num)
                                          }
                                          precision={0}
                                          min={0}
                                          max={10}
                                          snap
                                        />
                                      </div>
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                      ) : (
                        <></>
                      )}
                      <br></br>
                    </div>
                  )}
                </div>
                {this.state.viewSwitch ? (
                  <div id="projections-container">
                    {this.state.currentSport === "NFL" ? (
                      <div className="sort-buttons">
                        <button
                          className="sort-button-left"
                          onClick={this.sortPos}
                        >
                          ALL
                        </button>
                        <button
                          className="sort-button-inner"
                          onClick={this.sortPos}
                        >
                          QB
                        </button>
                        <button
                          className="sort-button-inner"
                          onClick={this.sortPos}
                        >
                          RB
                        </button>
                        <button
                          className="sort-button-inner"
                          onClick={this.sortPos}
                        >
                          WR
                        </button>
                        <button
                          className="sort-button-inner"
                          onClick={this.sortPos}
                        >
                          TE
                        </button>
                        <button
                          className="sort-button-inner"
                          onClick={this.sortPos}
                        >
                          FLEX
                        </button>
                        <button
                          className="sort-button-right"
                          onClick={this.sortPos}
                        >
                          DST
                        </button>
                      </div>
                    ) : (
                      <></>
                    )}
                    {this.state.currentSport === "NBA" ? (
                      <div className="sort-buttons">
                        <button
                          className="sort-button-left"
                          onClick={this.sortPos}
                        >
                          ALL
                        </button>
                        <button
                          className="sort-button-inner"
                          onClick={this.sortPos}
                        >
                          PG
                        </button>
                        <button
                          className="sort-button-inner"
                          onClick={this.sortPos}
                        >
                          SG
                        </button>
                        <button
                          className="sort-button-inner"
                          onClick={this.sortPos}
                        >
                          SF
                        </button>
                        <button
                          className="sort-button-inner"
                          onClick={this.sortPos}
                        >
                          PF
                        </button>
                        <button
                          className="sort-button-right"
                          onClick={this.sortPos}
                        >
                          C
                        </button>
                      </div>
                    ) : (
                      <></>
                    )}
                    {this.state.currentSport === "MLB" ? (
                      <div className="sort-buttons">
                        <button
                          className="sort-button-left"
                          onClick={this.sortPos}
                        >
                          ALL
                        </button>
                        <button
                          className="sort-button-inner"
                          onClick={this.sortPos}
                        >
                          P
                        </button>
                        <button
                          className="sort-button-inner"
                          onClick={this.sortPos}
                        >
                          C
                        </button>
                        <button
                          className="sort-button-inner"
                          onClick={this.sortPos}
                        >
                          1B
                        </button>
                        <button
                          className="sort-button-inner"
                          onClick={this.sortPos}
                        >
                          2B
                        </button>
                        <button
                          className="sort-button-inner"
                          onClick={this.sortPos}
                        >
                          3B
                        </button>
                        <button
                          className="sort-button-inner"
                          onClick={this.sortPos}
                        >
                          SS
                        </button>
                        <button
                          className="sort-button-right"
                          onClick={this.sortPos}
                        >
                          OF
                        </button>
                      </div>
                    ) : (
                      <></>
                    )}
                    <div className="table-div">
                      <table className="dfs-table">
                        <thead>
                          <tr>
                            <th>
                              <input
                                placeholder="Search by name..."
                                value={this.state.searchTerm}
                                onChange={this.search}
                              />
                            </th>
                            <th>
                              <input
                                placeholder="Search by team..."
                                value={this.state.searchTeamTerm}
                                onChange={this.searchTeam}
                              />
                            </th>
                            <th>
                              <button onClick={this.projectionSort}>
                                Sort By Projections
                              </button>
                            </th>
                            <th>
                              <button onClick={this.valueSort}>
                                Sort By Value
                              </button>
                            </th>
                            <th>
                              <button onClick={this.salarySort}>
                                Sort By Salary
                              </button>
                            </th>
                          </tr>
                        </thead>
                      </table>
                      <table className="dfs-table">
                        <thead>
                          <tr>
                            <th>Player</th>
                            <th>Position</th>
                            <th>Team</th>
                            <th>Cost</th>
                            <th>Def Rank</th>
                            <th>Projected Ownership</th>
                            {this.state.chosen_slate.game_type !==
                              "Classic" && <th>Projected CPT Ownership </th>}
                            <th>Value</th>
                            <th>GR Projection</th>
                            <th>Exposure</th>
                          </tr>
                        </thead>
                        {this.state.contains ? (
                          <tbody>
                            {this.state.players
                              .filter(
                                (players) =>
                                  players.name
                                    .toLowerCase()
                                    .includes(
                                      this.state.searchTerm.toLowerCase()
                                    ) &&
                                  players.team
                                    .toLowerCase()
                                    .includes(
                                      this.state.searchTeamTerm.toLowerCase()
                                    ) && (!this.state.hideZeroProjs || (this.state.hideZeroProjs && (players.projectedPoints > 0))) &&
                                  (this.state.filteredPlayerTeams.length === 0 || (this.state.filteredPlayerTeams.includes(players.team)))
                                  &&
                                  (players.positions[0]
                                    .toLowerCase()
                                    .includes(
                                      this.state.selectedPos.toLowerCase()
                                    ) ||
                                    this.state.selectedPos
                                      .toLowerCase()
                                      .includes(
                                        players.position.toLowerCase()
                                      ) ||
                                    (this.state.flexPos[players.name] !==
                                      undefined &&
                                      this.state.selectedPos
                                        .toLowerCase()
                                        .includes(
                                          this.state.flexPos[
                                            players.name
                                          ].toLowerCase()
                                        )))
                              )
                              .map((aLine, index) => {
                                if ((this.state.chosen_slate.game_type ===
                                  "Classic" || aLine.rosterPosition !== "CPT")) {
                                  return (
                                    <tr className="proj-row">
                                      <td className="proj-cell">
                                        <div className="headshot-td">
                                          <div className="headshot-td-img">
                                            <img
                                              className="headshot-td-img"
                                              src={aLine.headshot}
                                              alt="img"
                                            />
                                            {aLine.name}
                                          </div>
                                        </div>
                                      </td>
                                      {/* {aLine.position === "FLEX" ? (
                                        <td className="proj-cell">
                                          {this.state.flexPos[aLine.name]}
                                        </td>
                                      ) : (  Comment test for Showdown Positional change*/}
                                      <td className="proj-cell">
                                        {aLine.positions}
                                      </td>
                                      {/* )} */}
                                      {/* <td className="proj-cell">{aLine.team}</td> */}
                                      <td className="proj-cell">
                                      {aLine.team}
                                        {/* <div className="headshot-td">
                                          <div className="headshot-td-img">
                                            {this.currentSport === "NBA" && (
                                              <img
                                                className="player-count-team-image"
                                                src={require(`../../resources/${this.state.currentSport}logos/${this.state.currentSport}_${aLine.team}.png`)}
                                                alt="filler"
                                              />
                                            )}
                                          </div>
                                        </div> */}
                                      </td>
                                      <td className="proj-cell">
                                        {aLine.salary}
                                      </td>
                                      <td className="proj-cell">{aLine.def}</td>

                                      <td className="proj-cell">
                                        {this.state.ownership[aLine.apiID]}%
                                      </td>
                                      {this.state.chosen_slate.game_type !==
                                        "Classic" && (
                                          <td className="proj-cell">
                                            {this.state.ownershipCPT[aLine.apiID]}
                                            %
                                          </td>
                                        )}
                                      <td className="proj-cell">
                                        {(
                                          aLine.projectedPoints /
                                          (aLine.salary / 1000)
                                        ).toFixed(1)}
                                      </td>
                                      {/* <td className="proj-cell">
                                    {this.state.consensus[aLine.name]}
                                  </td> */}
                                      <td className="proj-cell">
                                        <input
                                          type="number"
                                          inputMode="numeric"
                                          className="num-input-table"
                                          onChange={(num) =>
                                            this.updateProjectionNum(num, aLine)
                                          }
                                          precision={2}
                                          min={0}
                                          max={100}
                                          placeholder={aLine.projectedPoints}
                                          snap
                                        />
                                      </td>
                                      <td className="proj-cell">
                                        <input
                                          type="number"
                                          inputMode="numeric"
                                          className="num-input-table"
                                          onChange={(num) =>
                                            this.updateExposurePerPlayer(
                                              num,
                                              aLine.apiID
                                            )
                                          }
                                          precision={2}
                                          min={0}
                                          max={100}
                                          placeholder={100}
                                          snap
                                        />
                                      </td>
                                      {/* <td>{aLine.Proj}</td> */}
                                    </tr>
                                  );
                                } else {
                                  return "";
                                }
                              })}
                          </tbody>
                        ) : (
                          <>
                            <tbody>
                              <tr>
                                <td
                                  className="hidden-projections"
                                  colSpan={"100%"}
                                >
                                  <h4>Subscribe to see our projections</h4>
                                  <div className="dfs-projections-button">
                                    <a className="dfs-pricing" href="/pricing">
                                      <button className="login-button">
                                        View Pricing Options
                                      </button>
                                    </a>
                                    <a href="/coming-soon">Upcoming Features</a>
                                  </div>
                                </td>
                              </tr>

                              <div className="ss-listings">
                                <tr>
                                  <h1>View and change Projections</h1>
                                  <div className="ss-div">
                                    <div className="ss-image-div">
                                      <img
                                        className="ss-image"
                                        src={ss1}
                                        alt="img"
                                      ></img>
                                    </div>
                                  </div>
                                </tr>
                                <tr>
                                  <h1>View Generated Lineups</h1>
                                  <div className="ss-div">
                                    <div className="ss-image-div">
                                      <img
                                        className="ss-image"
                                        src={ss2}
                                        alt="img"
                                      ></img>
                                    </div>
                                  </div>
                                </tr>
                                <tr>
                                  <h1>
                                    View Exposure Rate for Players in Given
                                    Lineups
                                  </h1>
                                  <div className="ss-div">
                                    <div className="ss-image-div">
                                      <img
                                        className="ss-image"
                                        src={ss3}
                                        alt="img"
                                      ></img>
                                    </div>
                                  </div>
                                </tr>
                              </div>
                            </tbody>
                          </>
                        )}
                      </table>
                    </div>
                  </div>
                ) : (
                  <div>
                    {this.state.lineups.length !== 0 ? (
                      <div>
                        {this.state.csvData.length !== 0 ? (
                          <CsvDownloader
                            className="csv-downloader"
                            filename={"GRSports_Lineup_" + this.state.today}
                            separator=","
                            wrapColumnChar=""
                            columns={
                              this.state.chosen_slate.game_type === "Classic"
                                ? this.state.needPos
                                : this.state.needPosShowdown
                            }
                            datas={this.state.csvData}
                            text="DOWNLOAD Lineups to CSV"
                          />
                        ) : (
                          <></>
                        )}
                        {this.state.filteredExposurePlayer !== undefined &&
                          <div className="exposureFilter-container">
                            <div className="exposureFilter" onClick={() => { this.setState({ filteredExposurePlayer: undefined, filteredExposurePercentage: [], filteredExposureLength: 1 }) }}>
                              {this.state.filteredExposurePlayer.name}
                              <div className="exposureFilter-cancel">
                                X
                              </div>
                            </div>
                          </div>
                        }
                        <div className="table-div">
                          <table className="dfs-table">
                            {
                              //Make this header use a state variable for positions
                            }
                            <thead>
                              <tr>
                                <th>Line #</th>
                                <th>Projection</th>
                                <th>Salary</th>
                                {this.state.chosen_slate.game_type === "Classic"
                                  ? this.state.needPos.map((aPos) => {
                                    return <th>{aPos.displayName}</th>;
                                  })
                                  : this.state.needPosShowdown.map((aPos) => {
                                    return <th>{aPos.displayName}</th>;
                                  })}
                              </tr>
                            </thead>
                            <tbody>

                              {
                                this.state.filteredExposurePlayer !== undefined ?


                                  this.state.lineups.filter(e => e.lineup.some(i => i.includes(this.state.filteredExposurePlayer.draftableId)))
                                    .map((aLine, index) => {
                                      return (
                                        <tr className="proj-row">
                                          <td className="proj-cell">{index + 1}</td>
                                          <td className="proj-cell">{aLine.proj}</td>
                                          <td className="proj-cell">{aLine.sal}</td>
                                          {aLine.lineup.map((draftableId) => {
                                            return (
                                              /*
                                            <td className="proj-cell" style={{textShadow: "black", color:"white", backgroundSize: "cover", backgroundImage: 
                                            this.state.currentSport==="NBA"?`url(${require(`../../resources/${this.state.currentSport}logos/NBA_${player.team}.png`)})`:''
                                            }}>
                                              {player.name}
                                            </td>*/
                                              <td className="proj-cell" onClick={() => { this.setFilteredExposurePercentage(draftableId) }}>
                                                {this.state.playerIdToPlayer[draftableId].name}
                                              </td>
                                            );
                                          })}
                                        </tr>
                                      );
                                    })

                                  :
                                  this.state.lineups.map((aLine, index) => {
                                    return (
                                      <tr className="proj-row">
                                        <td className="proj-cell">{index + 1}</td>
                                        <td className="proj-cell">{aLine.proj}</td>
                                        <td className="proj-cell">{aLine.sal}</td>
                                        {aLine.lineup.map((draftableId) => {
                                          return (
                                            /*
                                          <td className="proj-cell" style={{textShadow: "black", color:"white", backgroundSize: "cover", backgroundImage: 
                                          this.state.currentSport==="NBA"?`url(${require(`../../resources/${this.state.currentSport}logos/NBA_${player.team}.png`)})`:''
                                          }}>
                                            {player.name}
                                          </td>*/
                                            <td className="proj-cell" onClick={() => { this.setFilteredExposurePercentage(draftableId) }}>
                                              {this.state.playerIdToPlayer[draftableId].name}
                                            </td>
                                          );
                                        })}
                                      </tr>
                                    );
                                  })
                              }
                            </tbody>
                          </table>
                        </div>

                        {Object.keys(this.state.player_exposure).length !==
                          0 && (
                            <div className="table-div">
                              <table className="dfs-table">
                                {
                                  //Make this er use a state variable for positions
                                }
                                <thead>
                                  <tr>
                                    <th>Appearances #</th>
                                    <th>Appearances %</th>
                                    <th>Name</th>
                                    <th>Projection</th>
                                    <th>Salary</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {Object.keys(this.state.filteredExposurePercentage).length === 0 ?
                                    Object.keys(this.state.player_exposure)
                                      .sort((a, b) =>
                                        this.state.player_exposure[a].amount <
                                          this.state.player_exposure[b].amount
                                          ? 1
                                          : -1
                                      )
                                      .map((aLine) => {

                                        return (

                                          <tr className="proj-row">
                                            <td className="proj-cell">
                                              {
                                                this.state.player_exposure[aLine]
                                                  .amount
                                              }
                                            </td>
                                            <td className="proj-cell">
                                              {(
                                                (this.state.player_exposure[aLine]
                                                  .amount /
                                                  this.state.num_lines) *
                                                100
                                              ).toFixed(1)}
                                              %
                                            </td>
                                            <td className="proj-cell" >
                                              <div className="headshot-td" onClick={() => { this.setFilteredExposurePercentage(aLine) }}>
                                                <div className="headshot-td-img" >
                                                  <img
                                                    className="headshot-td-img"
                                                    src={
                                                      this.state.player_exposure[
                                                        aLine
                                                      ].player.headshot
                                                    }
                                                    alt="img"
                                                  />
                                                  {/* {this.state.playerIdToPlayer[aLine]} */}
                                                  {this.state.player_exposure[
                                                    aLine
                                                  ].player.name}
                                                </div>
                                              </div>
                                            </td>
                                            <td className="proj-cell">
                                              {Number(
                                                this.state.player_exposure[aLine]
                                                  .player.proj
                                              ).toFixed(2)}
                                            </td>
                                            <td className="proj-cell">
                                              {
                                                this.state.player_exposure[aLine]
                                                  .player.salary
                                              }
                                            </td>
                                          </tr>
                                        );
                                      }) :
                                    //switched to filted exposure
                                    Object.keys(this.state.filteredExposurePercentage)
                                      .sort((a, b) =>
                                        this.state.filteredExposurePercentage[a].amount <
                                          this.state.filteredExposurePercentage[b].amount
                                          ? 1
                                          : -1
                                      )
                                      .map((aLine) => {

                                        return (
                                          <tr className="proj-row">
                                            <td className="proj-cell">
                                              {
                                                this.state.filteredExposurePercentage[aLine]
                                                  .amount
                                              }
                                            </td>
                                            <td className="proj-cell">
                                              {(
                                                (this.state.filteredExposurePercentage[aLine]
                                                  .amount /
                                                  this.state.filteredExposureLength) *
                                                100
                                              ).toFixed(1)}
                                              %
                                            </td>
                                            <td className="proj-cell" onClick={() => { this.setFilteredExposurePercentage(aLine) }}>
                                              <div className="headshot-td">
                                                <div className="headshot-td-img">
                                                  <img
                                                    className="headshot-td-img"
                                                    src={
                                                      this.state.filteredExposurePercentage[
                                                        aLine
                                                      ].player.headshot
                                                    }
                                                    alt="img"
                                                  />
                                                  {this.state.filteredExposurePercentage[
                                                    aLine
                                                  ].player.name}
                                                </div>
                                              </div>
                                            </td>
                                            <td className="proj-cell">
                                              {Number(
                                                this.state.filteredExposurePercentage[aLine]
                                                  .player.proj
                                              ).toFixed(2)}
                                            </td>
                                            <td className="proj-cell">
                                              {
                                                this.state.filteredExposurePercentage[aLine]
                                                  .player.salary
                                              }
                                            </td>
                                          </tr>
                                        );
                                      })}
                                </tbody>
                              </table>
                            </div>
                          )}
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}
export default Optimizer;
