import React, { Component, useEffect, useState } from "react";
import { userAccess } from '../../firebase';
import Subscription from "../payment/Subscription.js";
import $ from 'jquery';
import { Table } from 'react-bootstrap';
import { Loading } from "../loader/Loading.js";
import Select from 'react-select'
import "./SznNFL.css"
import axios from 'axios';



export function Subscribed() {
    const [players, setPlayers] = useState();
    const [options, setOptions] = useState();
    const [once, setOnce] = useState(false);
    const [myTeam, setMyTeam] = useState([]);
    const [suggest, setSuggest] = useState();
    const [needLoad, setNeedLoad] = useState(false);
    //In the order of QB, RB, WR, TE, FLEX, K, D
    const [myPosSize, setMyPosSize] = useState(
        {
            QB: 0,
            RB: 0,
            WR: 0,
            TE: 0,
            FLEX: 0,
            K: 0,
            D: 0,
            QBneed: 0,
            RBneed: 0,
            WRneed: 0,
            TEneed: 0,
            FLEXneed: 0,
            Kneed: 0,
            Dneed: 0
        }
    );

    //booleans used to hide divs
    const [team, setTeam] = useState(true);
    const [proj, setProj] = useState(false);
    const [draft, setDraft] = useState(false);


    // eslint-disable-next-line react-hooks/exhaustive-deps
    let temp = [];
    const [takenName, setTakenName] = useState("");
    const [takenTeam, setTakenTeam] = useState("");
    // const keys = {
    //     "Ocp-Apim-Subscription-Key":"e64e38296a464e4fb32415bbb5513857"
    // }

    // $.get('https://api.sportsdata.io/v3/nfl/stats/json/FantasyDefenseBySeason/2022Reg', {
    //     "key":"e64e38296a464e4fb32415bbb5513857"

    // }
    //   ).then((response) => {
    //     console.log(response);
    // });
    if (!once) {
        $.post(
            //"http://localhost:8080/downloadProj",
            "http://localhost:8080/customDraft",
            //{subscriptionId: sub_id},
            function (data) {
                setPlayers(data);
                if (players != null) {
                    temp.push(players.map((aPlayer) => {

                        return ({ value: { player: aPlayer.player[1], team: aPlayer.player[0], position: aPlayer.player[2] }, label: (aPlayer.player[1] + "," + aPlayer.player[0]) });
                        //setOptions(temp);
                        //

                    })) 

                     
                    setOptions(temp);
                    setSuggest([players.at(0).player[1], players.at(1).player[1], players.at(2).player[1]]);
                }
                setOnce(true);
                //console.log(players[23].player[0]);
            }, 'json'
        ).then((response) => {
            //console.log(response);
            //alert("cancel subscription success");
        })
            .catch((error) => {
                console.log(error);
                //alert("cancel subscription failed");
            });
    }

    function sortByName() {
        $.post(
            "http://localhost:8080/sortName",
            //{subscriptionId: sub_id},
            function (data) {
                setPlayers(data);
                //console.log(players[23].player[0]);
            }, 'json'
        ).then((response) => {
            //console.log(response);
            //alert("cancel subscription success");
        })
            .catch((error) => {
                console.log(error);
                //alert("cancel subscription failed");
            });
    }

    function sortByTeam() {
        $.post(
            "http://localhost:8080/sortTeam",
            //{subscriptionId: sub_id},
            function (data) {
                setPlayers(data);
                //console.log(players[23].player[0]);
            }, 'json'
        ).then((response) => {
            //console.log(response);
            //alert("cancel subscription success");
        })
            .catch((error) => {
                console.log(error);
                //alert("cancel subscription failed");
            });
    }

    function sortByPos() {
        $.post(
            "http://localhost:8080/sortPos",
            //{subscriptionId: sub_id},
            function (data) {
                setPlayers(data);
                //console.log(players[23].player[0]);
            }, 'json'
        ).then((response) => {
            //console.log(response);
            //alert("cancel subscription success");
        })
            .catch((error) => {
                console.log(error);
                //alert("cancel subscription failed");
            });
    }

    function sortByPosRank() {
        $.post(
            "http://localhost:8080/sortPosRank",
            //{subscriptionId: sub_id},
            function (data) {
                setPlayers(data);
                //console.log(players[23].player[0]);
            }, 'json'
        ).then((response) => {
            //console.log(response);
            //alert("cancel subscription success");
        })
            .catch((error) => {
                console.log(error);
                //alert("cancel subscription failed");
            });
    }

    function sortByTier() {
        $.post(
            "http://localhost:8080/sortTier",
            //{subscriptionId: sub_id},
            function (data) {
                setPlayers(data);
                //console.log(players[23].player[0]);
            }, 'json'
        ).then((response) => {
            //console.log(response);
            //alert("cancel subscription success");
        })
            .catch((error) => {
                console.log(error);
                //alert("cancel subscription failed");
            });
    }

    function myPick(aPlayer) {
        setMyTeam(old => [...old, aPlayer.value]);
        setTakenName(aPlayer.value.player);
        setTakenTeam(aPlayer.value.team);

        let name = aPlayer.value.position;
        let num = myPosSize[name] + 1;
        let newPos = { [name]: num };
        setNeedLoad(true);
        setMyPosSize({ ...myPosSize, ...newPos });
    }
    useEffect(() => {
        if (needLoad) {
            console.log(myPosSize)
            setNeedLoad(false);
            axios.post(
                "http://localhost:8080/newPick?takenName=" + takenName + "&takenTeam=" + takenTeam,
                myPosSize
            )
                .then(response => response.data)
                .then((data) => {
                    if (data != null) {
                        temp.push(data.map((aPlayer) => {
                            return({ value: { player: aPlayer.player[1], team: aPlayer.player[0], position: aPlayer.player[2] }, label: (aPlayer.player[1] + "," + aPlayer.player[0]) });

                        }))
                        setSuggest([data.at(0).player[1], data.at(1).player[1], data.at(2).player[1]]);
                        setOptions(temp);

                    }
                });
        }
    }, [needLoad, myPosSize, takenName, takenTeam, temp])

    function theirPick(aPlayer) {
        setTakenName(aPlayer.value.player);
        setTakenTeam(aPlayer.value.team);
        setNeedLoad(true);
    }
    function setTeamSize(pos) {
        let name = pos.value + "need";
        let num = pos.label;
        const newPos = { [name]: num };

        setMyPosSize({ ...myPosSize, ...newPos });

    }
    return (
        <div>
            {team === true ?
                <div id="teamComp">
                    First step is to let us know how many players per position your league requires
                    <br></br>
                    <Table>
                        <tr>
                            <td>QB</td>
                            <td><Select id="qbAmount"
                                // create local const and map the set to onClick for each option then add button

                                options={[
                                    { label: "0", value: "QB" },
                                    { label: "1", value: "QB" },
                                    { label: "2", value: "QB" },
                                    { label: "3", value: "QB" },
                                    { label: "4", value: "QB" },
                                    { label: "5", value: "QB" }
                                ]}
                                onChange={setTeamSize}

                            /></td>
                        </tr>
                        <tr>
                            <td>RB</td>
                            <td><Select id="rbAmount"
                                // create local const and map the set to onClick for each option then add button

                                options={[
                                    { label: "0", value: "RB" },
                                    { label: "1", value: "RB" },
                                    { label: "2", value: "RB" },
                                    { label: "3", value: "RB" },
                                    { label: "4", value: "RB" },
                                    { label: "5", value: "RB" }
                                ]}
                                onChange={setTeamSize}

                            /></td>
                        </tr>
                        <tr>
                            <td>WR</td>
                            <td><Select id="wrAmount"
                                // create local const and map the set to onClick for each option then add button

                                options={[
                                    { label: "0", value: "WR" },
                                    { label: "1", value: "WR" },
                                    { label: "2", value: "WR" },
                                    { label: "3", value: "WR" },
                                    { label: "4", value: "WR" },
                                    { label: "5", value: "WR" }
                                ]}
                                onChange={setTeamSize}

                            /></td>
                        </tr>
                        <tr>
                            <td>TE</td>
                            <td><Select id="teAmount"
                                // create local const and map the set to onClick for each option then add button

                                options={[
                                    { label: "0", value: "TE" },
                                    { label: "1", value: "TE" },
                                    { label: "2", value: "TE" },
                                    { label: "3", value: "TE" },
                                    { label: "4", value: "TE" },
                                    { label: "5", value: "TE" }
                                ]}
                                onChange={setTeamSize}

                            /></td>
                        </tr>
                        <tr>
                            <td>FLEX</td>
                            <td><Select id="flexAmount"
                                // create local const and map the set to onClick for each option then add button

                                options={[
                                    { label: "0", value: "FLEX" },
                                    { label: "1", value: "FLEX" },
                                    { label: "2", value: "FLEX" },
                                    { label: "3", value: "FLEX" },
                                    { label: "4", value: "FLEX" },
                                    { label: "5", value: "FLEX" }
                                ]}
                                onChange={setTeamSize}

                            /></td>
                        </tr>
                        <tr>
                            <td>K</td>
                            <td><Select id="kAmount"
                                // create local const and map the set to onClick for each option then add button

                                options={[
                                    { label: "0", value: "K" },
                                    { label: "1", value: "K" },
                                    { label: "2", value: "K" },
                                    { label: "3", value: "K" },
                                    { label: "4", value: "K" },
                                    { label: "5", value: "K" }
                                ]}
                                onChange={setTeamSize}

                            /></td>
                        </tr>
                        <tr>
                            <td>D</td>
                            <td><Select id="dAmount"
                                // create local const and map the set to onClick for each option then add button

                                options={[
                                    { label: "0", value: "D" },
                                    { label: "1", value: "D" },
                                    { label: "2", value: "D" },
                                    { label: "3", value: "D" },
                                    { label: "4", value: "D" },
                                    { label: "5", value: "D" }
                                ]}
                                onChange={setTeamSize}

                            /></td>
                        </tr>
                    </Table>
                    <button onClick={() => {
                        setTeam(false);
                        setProj(true);
                    }
                    }>Accept Team Constraints</button>
                </div>
                : <></>
            }
            {proj === true ?
                <div id="projections">
                    <button onClick={() => {
                        setProj(false);
                        setDraft(true);
                    }}>Accept Projections</button>

                    <Table>
                        <thead>
                            <tr>
                                <th onClick={sortByTeam}>Team</th>
                                <th onClick={sortByName}>Player</th>
                                <th onClick={sortByPos}>Position</th>
                                <th onClick={sortByTier}>Tier</th>
                                <th onClick={sortByPosRank}>Position Rank</th>
                            </tr>
                        </thead>

                        <tbody id="body">
                            {players != null &&
                                players.map((aPlayer) => (
                                    <tr>
                                        <td>{aPlayer.player[0]}</td>
                                        <td>{aPlayer.player[1]}</td>
                                        <td>{aPlayer.player[2]}</td>
                                        <td>{aPlayer.player[3]}</td>
                                        <td>{aPlayer.player[4]}</td>

                                    </tr>
                                ))
                            }
                        </tbody>


                    </Table>
                </div>
                : <></>
            }
            {draft === true ?
                <div id="draft">
                    Our Top Picks for You
                    <Table>
                        {suggest != null &&
                            suggest.map((aPlayer) => (
                                <tr>
                                    <td>{aPlayer}</td>
                                </tr>
                            ))
                        }
                    </Table>
                    My Draft Pick
                    {temp != null &&
                        <Select id="myPick"
                            // create local const and map the set to onClick for each option then add button

                            options={options}
                            onChange={myPick}

                        />


                    }
                    Opponent Draft Pick
                    {temp != null &&
                        <Select id="theirPick"
                            // create local const and map the set to onClick for each option then add button

                            options={options}
                            onChange={theirPick}

                        />


                    }
                    Your current Players
                    <div className="myTeamTable">
                        <Table className="posTable" >
                            {//somehow make programatically so it fits input team comp
                            }
                            <thead>
                                <tr>
                                    <th>QB</th>
                                </tr>
                            </thead>
                            <tbody>
                                {myTeam != null &&
                                    myTeam.map((aPlayer) => (
                                        <tr>

                                            {aPlayer.position === "QB" ?
                                                <th>{aPlayer.player}</th>
                                                : <></>}

                                        </tr>
                                    ))
                                }
                            </tbody>
                        </Table>
                        <Table class="posTable" >
                            {//somehow make programatically so it fits input team comp
                            }
                            <thead>
                                <tr>
                                    <th>RB</th>
                                </tr>
                            </thead>
                            <tbody>
                                {myTeam != null &&
                                    myTeam.map((aPlayer) => (
                                        <tr>

                                            {aPlayer.position === "RB" ?
                                                <th>{aPlayer.player}</th>
                                                : <></>
                                            }
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </Table>
                        <Table className="posTable" >
                            {//somehow make programatically so it fits input team comp
                            }
                            <thead>
                                <tr>
                                    <th>WR</th>
                                </tr>
                            </thead>
                            <tbody>
                                {myTeam != null &&
                                    myTeam.map((aPlayer) => (
                                        <tr>
                                            {aPlayer.position === "WR" ?
                                                <th>{aPlayer.player}</th>
                                                :
                                                <></>
                                            }

                                        </tr>
                                    ))
                                }
                            </tbody>
                        </Table>
                        <Table className="posTable" >
                            {//somehow make programatically so it fits input team comp
                            }
                            <thead>
                                <tr>
                                    <th>TE</th>
                                </tr>
                            </thead>
                            <tbody>
                                {myTeam != null &&
                                    myTeam.map((aPlayer) => (
                                        <tr>

                                            {aPlayer.position === "TE" ?
                                                <th>{aPlayer.player}</th>
                                                : <></>}

                                        </tr>
                                    ))
                                }
                            </tbody>
                        </Table>
                    </div>
                </div>
                : <></>
            }
        </div>
    )
}

export function NotSubscribed() {
    return (
        <div>
            <h1>YOU DO NOT HAVE ACCESS TO THIS PAGE, IF YOU WISH TO HAVE ACCESS SUBSCRIBE BELOW</h1>
            <Subscription sub_name="Season Long Football" sub_id="price_1L5JysEkQUfPAPtH9IPf3vC0" />
        </div>
    )
}
class SznNFL extends Component {
    constructor(props) {
        super(props);
        console.log("testing");
        console.log(props.subs);
        this.state = { subs: userAccess(), contains: false, loading: true };
        //this.checker();
        //this.unsubscribe = this.unsubscribe.bind(this);
    }

    async componentDidMount() {

        await new Promise(resolve => { setTimeout(resolve, 500); });
        this.setState({ subs: await userAccess() }, () => {
            this.state.subs.map((aLine) => {
                if (aLine.access === "Season Long Football") {
                    this.setState({ contains: true, loading: false });
                }
                return aLine;
            }
            );
            if (this.state.contains !== true) {
                this.setState({ loading: false })

            }
            return Promise.resolve();
        });


    }

    render() {
        return (
            <div>{
                this.state.loading ? <Loading /> :
                    this.state.contains ? <Subscribed /> : <NotSubscribed />
            }

            </div>
        )
    }
}
export default SznNFL;