import React from "react";
import $ from "jquery";
import CsvDownload from 'react-json-to-csv';
import { useState } from "react";
import { heroku } from "../../resources/config";



export function SheetInfo() {
    const [projs, setProjs] = useState([]);
    const [odds, setOdds] = useState([]);

    const getProjs = (() => {
            $.get('https://grdfs-d0aee-default-rtdb.firebaseio.com/sheetInfo/Players.json'
            ).then((response) => {
                console.log(response);
                setProjs(response);
                document.getElementById("playerDownload").click();
                getOdds();
                })
                .catch((error) => {
                    console.log(error);
                    //alert("cancel subscription failed");
                });
    });

    const updateFiles = (()=> {
        $.get(heroku + "statProjectionsNFL"
            ).then((response) => {
                alert(response);
                })
                .catch((error) => {
                    console.log(error);
                    //alert("cancel subscription failed");
                });
    });

    const getOdds = (() => {
        $.get('https://grdfs-d0aee-default-rtdb.firebaseio.com/sheetInfo/Odds.json'
        ).then((response) => {
            console.log(response);
            setOdds(response);
            document.getElementById("oddsDownload").click();
            })
            .catch((error) => {
                console.log(error);
                //alert("cancel subscription failed");
            });
});


    
    return (
        <div>
            <h1>FOR JASON's SHEET</h1>
            <h2>Press "Update Files" Button and wait for it to tell you it is ready</h2>
            <h2> Afterwards press "Grab Files" in order to download files to your Computer</h2>
            <button onClick={updateFiles}>Update Files</button>
            <button onClick={getProjs}>Grab Files</button>
            <br></br>
            <CsvDownload id="playerDownload" hidden="true" data={projs} filename={"PlayerStats.csv"} />
            <CsvDownload id="oddsDownload" hidden="true" data={odds} filename={"Odds.csv"}/>
            <br></br>
        </div>);
}