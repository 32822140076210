import React from "react";
import ReactDOM from "react-dom";
import "./DownloadDesktop.css";

export default function DownloadDesktop() {

    return (
        <div className="DesktopDownloadContainer">
            <h1>Download our Desktop Application!</h1>
        <div>
            We recommend downloading this desktop version while our website is still in production!
            <br></br>
            <br></br>
            Please visit our Discord for any help or questions you may have
            <br></br>
            <br></br>
            The following prerequisites are required to run this desktop application:<br></br><br></br>
            Windows Computer
            <br></br>
            Visual C++ "14" Runtime Libraries (x64)
            <br></br>
        </div>
        <button className="optomize-button" onClick={()=>{
            document.getElementById("downloadDesktop").click();
        }}>Download Desktop Application</button>

        <a hidden="true" id="downloadDesktop" href="https://grimreaperstorage.z13.web.core.windows.net/setup.exe">Download</a>
        </div>
    )
}

const rootElement = document.getElementById("root");
ReactDOM.render(<DownloadDesktop />, rootElement);
