import React, { Component } from "react";
import { withRouter } from "../withRouter.js";
import "./ArticlesView.css";

//make this state variable and pass in which file to show via db
class ArticlesView extends Component {
  constructor(props) {
    super(props);
    
    this.state = {};
    this.change = this.change.bind(this);
  }

  change(url_name) {
    this.props.navigate("/" + url_name);
  }

  setPath(path) {
    console.log(path);
  }
  render() {
    return (
      <div className="ArticlesView-container">
        {/* {this.props.bar && <Navbar />} */}
        {this.props.title &&
        <h1 className="story-title">Exclusive Articles</h1>
        }
        <div className="cards-row">
          {this.props.articles.map((aArticle, index) => {
            return (
              <div
                className="card"
                onClick={() => {
                  this.change(aArticle.url_name);
                }}
              >
                <img src={aArticle.image} className="card-image" alt="" />

                <div className="card-info">
                  <div className="article-text">
                    <h3>{aArticle.path}</h3>
                  </div>
                  <div className="card-desc">
                    <p>{aArticle.desc}</p>
                  </div>
                  {
                    aArticle.exclusive &&
                    <div className="exclusive-card-div" title="Subscriber Only">
                      &#128274;
                    </div>
                  }
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}
export default withRouter(ArticlesView);
