import React, { Component } from "react";
import $ from 'jquery';

import "./Article.css";
import { Loading } from "../loader/Loading.js";
import { getAuth } from "firebase/auth";
import { onValue, ref } from "firebase/database";
import { db } from "../../firebase.js";
import { useNavigate } from "react-router";



export function NotSubscribed(props) {
    let navigate = useNavigate();
    // $(`#article-preview`).load(props.props.article.preview);
    return (
        <div>
            {console.log("yassers pp is very big")}

            <div className="article-wrapper">
                <div className="article-content-wrapper">
                    <div className="article-image-container">
                        <img className="article-image" width="auto" height="auto" src={props.props.article.image} alt="" />
                    </div>
                    <div id="article-preview">
                        {props.props.article.preview}
                    </div>
                    <div>
            <h1>To continue reading you must be subscribed</h1>
            <div>
            <button className="login-button" onClick={(()=>{navigate("/pricing")})}>View Pricing Options</button>
            </div>
            </div>
                </div>
            

            </div>


            
        </div>
    )
}

//make this state variable and pass in which file to show via db
class Article extends Component {

    constructor(props) {
        super(props);
        this.state = { numPages: null, pageNumber: 1, loading: true, contains: false };
    }

    componentDidMount() {
        // this.setState({contains:true, loading:false})
        if (this.props.article.exclusive === true) {
            
            const auth = getAuth();
            let user = auth.currentUser;
            if(user){
            let u_id = user.uid;
            let subRef = ref(db, "/users/" + u_id + "/subscriptions");
            onValue(subRef, ss => {
                //alert(JSON.stringify(ss.val()));
                if (ss.val()) {
                    let keys = Object.keys(ss.val());
                    keys.map(test => {
                        //console.log(ss.val()[test]);
                        //cancelFirebase(test);
                        //cancelSubscription(ss.val()[test]);
                        if (ss.val()[test].access === "DFS Football") {
                            this.setState({ contains: true, loading: false })


                        }
                        return ""
                    });
                    if (this.state.contains === false) {
                        this.setState({ loading: false })
                    }
                    //return tempSubs;
                } else {
                    this.setState({ loading: false })
                }

            });
            }else{
                this.setState({ contains: false, loading: false });
            }
        } else {
            this.setState({ contains: true, loading: false });
        }




    }

    componentDidUpdate() {
        $(`#article-container`).load(this.props.article.source);

        $(`#article-container`).on('DOMSubtreeModified', function () {
            $('#article-container a').attr('target', '_blank');
        });

    }



    render() {
        return (
            <div>
                {
                    this.state.loading ? <Loading /> :
                        !this.state.contains ? <NotSubscribed props={this.props}/> :

                            <div>
                                <div className="article-wrapper">
                                    <div className="article-content-wrapper">
                                        <div className="article-image-container">
                                            <img className="article-image" width="auto" height="auto" src={this.props.article.image} alt="" />
                                        </div>
                                        <div id="article-container" target="_blank">

                                        </div>
                                    </div>

                                </div>
                            </div>
                }
            </div>
        )
    }
}

export default Article;