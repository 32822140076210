import "./Signup.css"
import {getAuth, createUserWithEmailAndPassword} from "firebase/auth";
import {ref, set}from "firebase/database";
import { app,db } from '../../firebase';
import {useNavigate} from 'react-router-dom'
import logo from '../../resources/GRDFSLogo.png'
import eyeball from '../../resources/eyeball.png';
import { useState } from "react";
// import { toast } from 'react-toastify'

let auth = getAuth(app);
var email;
var pw;
var aPw;
export function Signup(admin){
    const getEmailValue = (event)=>{
        const userValue = event.target.value;
        email = userValue;
        // console.log(fn);
    };
    const getPassValue = (event)=>{
        const userValue = event.target.value;
        pw = userValue;
        // console.log(fn);
    };

    let navigate = useNavigate();
    function handleClick(event){
        event.preventDefault();
        navigate("/signin")
    }

    const test = (e) =>{
        e.preventDefault();
        let length = pw.length >= 6;
        let lowercase =/[a-z]/.test(pw);
        let capital =/[A-Z]/.test(pw);
        let numbers = /[0-9]/.test(pw);
        let special = /[\W]/.test(pw);
        let combine = length && lowercase && capital && numbers && special;

        // console.log(combine);
        if(!combine){
            setMessage(!message);
            // alert("Password doesn't meet requirements");
        }else{
            onFormSubmit(e);
        }
    }

    const toggleMessage = () =>{
        setMessage(!message);
    }

    const onFormSubmit = (e) =>{
        e.preventDefault()
        //firebase stuff
        createUserWithEmailAndPassword(auth, email, pw).then(somedata=>{
            let uid = somedata.user.uid;
            let userRef = ref(db, "/users/"+uid+"/username");
            set(userRef, email);
            let userRoleRef = ref(db, "/users/"+uid+"/roles/admin");
            if(!!admin.admin){
                set(userRoleRef, true);
                console.log("Admin register success")
            }else if(!admin.admin){
                set(userRoleRef, false);
                console.log("Register success")
            }
            navigate("/pricing");
          }).catch(function(error) {
            // Handle Errors here.
            var errorCode = error.code;
            var errorMessage = error.message;
            console.log(errorCode);
            console.log(errorMessage);
          });
    }

    const[passwordShown, setPasswordShown] = useState(false);
    const[opacityFull, setOpacityFull] = useState(false);
    const[checkAdmin, setCheckAdmin] = useState(admin.admin);
    const[message, setMessage] = useState(false);

    const togglePassword = () =>{
        setPasswordShown(!passwordShown);
        setOpacityFull(!opacityFull)
    }
     
    function adminPassword(){
        return(
            <div>
                Type in the Password
                
                <div>
                    <input type="text" onChange={changedAdminEntryPass}required/>
                </div>
                <div>
                    <button onClick={checkPassword}>Submit Password</button>
                </div>
            
            </div>
        );
    }

    function changedAdminEntryPass(e){
        aPw = e.target.value;
    }

    function checkPassword(){
        if(aPw === "e6987fayfda972bkaosf723bfkahfa0377agnlfasfiywe82938f0dahlnvlajfieaiwuefhkgaouweufpda8s97fnlkdaso23r4"){
            console.log("yep");
            setCheckAdmin(false);
        }else{
            navigate("/signup");
        }
    }

    return(
        <div>
        <div className='signin-body'>
            {/* {console.log(admin.admin)} */}
            {checkAdmin ?
            adminPassword()
            
            :
                <div className="signin-wrapper">
                    {message && <div className="requirements-message">
                        <button className="close-window" onClick={toggleMessage}>X</button>
                        <pre className="message-notification">
                            Password must meet the following requirements:<br/><br/>
                             - Must be six characters or longer<br/>
                             - Must contain one capital and lower case letter (a-z, A-Z)<br/>
                             - Must contain at least one number (0-9)<br/>
                             - Must contain one special character (!@#$%^.*-+=)<br/>
                        </pre>
                    </div>}
                    <div className='left-side-container'>
                        <div className='logo-wrapper'>
                            <img className='picture' src={logo} alt="logo"/>
                        </div>
                    </div>
                    <div className="right-side-container">
                        <div className="form-container">
                            <form onSubmit={test}>
                                <div className='inputInfo'>
                                    <input className="email-input" type="text" placeholder="First Name" id="firstName" required/>
                                    <input className="email-input" type="text" placeholder="Last Name" name="lastName" required/>
                                    <input className="email-input" type="text" placeholder="Username" name="username" required/>
                                    <input className="email-input" type="text" placeholder="Email" name="email" onChange={getEmailValue} required/>
                                    <div className="password-container">
                                        <input className="email-input" type={passwordShown ? "text" : "password"} placeholder="Password" name="password" onChange={getPassValue} required/>
                                        <span className="eye-container">
                                            <img className={opacityFull? "eyeball-see" : "eyeball"} src={eyeball} alt="eyeball" onClick={togglePassword} />
                                        </span>
                                    </div>
                                </div>
                                <div className='buttonsSignin'>
                                    <button className='login-button'type='submit'>Sign Up</button>
                                    <div className='separator'></div>
                                    <button className="signup-button"onClick={handleClick}>Sign In</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                }
            </div>
            </div>
    );
}