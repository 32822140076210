import React, { useEffect , useState} from "react";
import './navbar.css'
import {getAuth, signOut, onAuthStateChanged} from 'firebase/auth';
import grim from "../../resources/GRDFSLogo.png"

export function Navbar(){
    const [user, setUser] = useState();
  
    useEffect(() => {
      const unsubscribe = onAuthStateChanged(getAuth(), setUser) 
      return unsubscribe;
    }, []);

    return(
        <div className="navbar"> 
        {/* {loaded && */}
        <div>
                {/* <div className="dropdown" href="home"> */}
                    <a className="logo" href="/home"><img className="logo" src={grim} alt="GRSports Home"></img></a>
                    {/* <button className="dropbtn" onClick={handleClick}><i><b>GR Sports</b></i></button> */}
                {/* </div> */}
            <div className="dropdown">
                <button className="dropbtn">NFL</button>
                <div className="dropdown-content">

                    <a className="nav-link" href="read-nfl">Articles</a>
                    <a className="nav-link" href="game-preview">Game Preview</a>
                    <a className="nav-link" href="game-recap">Game Recap</a>
                    <a className="nav-link" href="/news">News</a>
                    <a className="nav-link" href="/dfs-nfl">Optimizer</a>
                </div>
            </div>
            <div className="dropdown">
                <button className="dropbtn">NBA</button>
                <div className="dropdown-content">
                    <a className="nav-link" href="/">Articles</a>
                    <a className="nav-link" href="/dfs-nba">Optimizer</a>
                </div>
            </div>
            <div className="dropdown">
                <button className="dropbtn">NHL</button>
                <div className="dropdown-content">
                    <a className="nav-link" href="/">Articles</a>
                    <a className="nav-link" href="/">Optimizer</a>

                </div>
            </div>
            <div className="dropdown">
                <button className="dropbtn">MLB</button>
                <div className="dropdown-content">

                    <a className="nav-link" href="/">Articles</a>
                    <a className="nav-link" href="/dfs-mlb">Optimizer</a>
                    {/* <a href="#">Other</a> */}
                </div>
            </div>
            <div className="dropdown">
                <button className="dropbtn">SOC</button>
                <div className="dropdown-content">
                    <a className="nav-link" href="/">Articles</a>
                    <a className="nav-link" href="/">Optimizer</a>
                </div>
            </div>

            

            <div className="account">
                <button className="dropbtn">Account</button>
                <div className="dropdown-content">
                    <a className="nav-link" href="/">Settings</a>
                    {/* <a className="nav-link" href="/my-subscriptions">My Subsciptions</a> */}
                    {user ? 
                    <a className="signOut" onClick={() => signOut(getAuth())} href="/">Sign Out{console.log(user)}</a>
                    :
                        <a className="signOut" href="/signin">Sign In/Sign Up{console.log(user)}</a>
                    }
                </div>
            </div>
            <div className="account">
                <a className="dropbtn" href="/subscriptions">Subsciptions</a>
            </div>
            </div>
            {/* } */}
        </div>
        
                
    )
}