import axios from "axios";
import {useState } from "react";
import "./NewsView.css"
import { useNavigate } from "react-router";
import { heroku } from "../../resources/config";

export function NewsView(props) {
  const [load, loaded] = useState(false);
  const [news, setNews] = useState([]);

  function getNews(){
    console.log(heroku);
    if (!load) {
        loaded(true);
        axios
          .get(heroku + "get-all-stories?leagueId=30")
          .then((response) => response.data)
          .then((data) => {
            setNews(data);
            console.log(data);
          });
    }
  }

  let navigate = useNavigate();

  return (
    <div className="news-page">
      <div className="news-container">
        {props.title? <h1 className="story-title">{props.title}</h1>:
        <h1 className="story-title">Live NFL News</h1>
        } 
        {!load ? getNews() :       
        news.slice(0,props.max).filter(story => 
          ((story.storyTypeId === props.storyTypeId) || !props.storyTypeId)
        )
        .sort((a,b) => new Date(b.lastExportedDate).getTime() - new Date(a.lastExportedDate).getTime()).map((story) => 
            
            <div className="news-card" value={story.storyId} onClick={()=>{
                
                navigate("/story", {state:{story: story}})
            }}>
              {story.images &&
              <img src={story.images[0].thumbUrl} className="news-card-image" alt="" value={story.storyId}/>
              }
            <div className="news-text" value={story.storyId}>
                <div className="news-card-title" value={story.storyId}>
                    <h3>{story.headline}</h3>
                </div>
                {story.images &&
                <div className="news-card-desc" value={story.storyId}><p className="news-card-desc-text">{story.storyText.substr(0,130)+"..."}</p></div>
                }
           </div>
          </div>
        )}
      </div>
    </div>
  );
}
