import React, {Component } from 'react';
import $ from 'jquery'; 
import StripeCheckout from 'react-stripe-checkout';
import {getAuth} from "firebase/auth";
import {heroku} from "../../resources/config"
import {ref, push}from "firebase/database";
import { db } from '../../firebase';
// import {testlocal} from "../../resources/config";

class Subscription extends Component {
    constructor(props){
        super(props);
        
        this.handleToken = this.handleToken.bind(this);
        //this.unsubscribe = this.unsubscribe.bind(this);
    }

    // const options = [
    //     { value: 'price_1L5JysEkQUfPAPtH9IPf3vC0', label: 'Weekly'},
    //     { value: 'price_1L4xmfEkQUfPAPtH3NKzxcfE', label: 'Monthly' },
    //     { value: 'price_1L4FrzEkQUfPAPtHWPIEyWoz', label: 'Yearly' },
    // ]
    // var chosen_package;
    
    // function changePackage(option){
    //     chosen_package  = option;
    // }
    // function submitPackage(){
    //    console.log(chosen_package);
    //    $('#checkout').show();
    // }

    // addSubscription(sub_id){     When turned to class "this" makes func not work
    //     const auth = getAuth();
    //     let user = auth.currentUser;
    //     let u_id = user.uid;
    //     let subRef = ref(db, "/users/" + u_id + "/subscriptions");
    //     push(subRef, sub_id);
    // }


    handleToken(token, addresses){
        // console.log({token, addresses})
        // Send the token to your server.
        //var token = token.id;
        var plan = this.props.sub_id;
        var sub_name = this.props.sub_name;
        var email = token.email;
        var isTrial = this.props.trial;
        console.log(email);
        var coupon = "";
        $.post(
            heroku + "create-subscription",
            {email: email, token: token.id, plan: plan, coupon: coupon, trial: isTrial},
            function (data) {
                let sub_id = data.details;
                const auth = getAuth();
                let user = auth.currentUser;
                let u_id = user.uid;
                let subRef = ref(db, "/users/" + u_id + "/subscriptions");
                push(subRef, {id: sub_id, access: sub_name});
                // document.getElementById("downloadDesktop").click();
                document.getElementById("reroute").click();
            }, 'json'
      ).then((response) => {
        alert("payment success");
      })
      .catch((error) => {
        alert("Payment failed");
      });
    }
    render (){
    return (
        <div>
            {/*
            Create your custom package
            <Select id="packages"
                isMulti
                name="colors"
                options={options}
                className="basic-multi-select"
                classNamePrefix="select"
                onChange={options => changePackage(options)}
            />
            <button onClick={submitPackage}>
                Finalize Selections
            </button>
            */}

            {//<div id="checkout" hidden="true">
            }
            <StripeCheckout
            //live key
            stripeKey="pk_live_51HLG9EEkQUfPAPtHBavjweJWFmoVJuooVRdxeQVYpQRhlpon87WxS2VNhnUzCum2WMvGMcZiQ7lzovU0GoxkVlzC00CC3nWoVK"
            token={this.handleToken}
            email
            billingAddress
            shippingAddress            
            amount={this.props.price}
            name={"GrimReaperDFS"}
            description="No charge until a week from today" // the pop-in header subtitle
            label="Subscribe"
            panelLabel="Start Trial"
            //
            />
            
            <a id="reroute" hidden="true" href="/desktop-download">Hidden</a>
            
        </div>
    );
    }
}
export default Subscription;