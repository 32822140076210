// import axios from "axios";
// import {useState } from "react";
// import "./NewsSlides.css"
// import { useNavigate } from "react-router";
// import { heroku } from "../../resources/config";

// // Import Swiper React components
// import { Swiper, SwiperSlide } from 'swiper/react';
// import { Navigation, A11y } from 'swiper';
// import SwiperCore, { Autoplay } from 'swiper';

// // Import Swiper styles
// import 'swiper/swiper.min.css';

// import 'swiper/swiper-bundle.min.css';

export function NewsSlides(props) {
  //const [load, loaded] = useState(false);
  //const [news, setNews] = useState([]);

  // function getNews(){
  //   //SwiperCore.use([Autoplay])
  //   //console.log(heroku);
  //   // if (!load) {
  //   //     loaded(true);
  //   //     axios
  //   //       .get(heroku + "get-all-stories?leagueId=30")
  //   //       .then((response) => response.data)
  //   //       .then((data) => {
  //   //         setNews(data);
  //   //         console.log(data);
  //   //       });
  //   // }
  // }

  //let navigate = useNavigate();

  return (
    <div>

    </div>
    // <div className="news-slide-page">
    //   <div className="news-slide-container">
    //   <Swiper
    //   // install Swiper modules
    //   modules={[Navigation, A11y]}
    //   slidesPerView={1}
    //   navigation
    //   loop={true}
    //   autoplay={{ delay: 5000, disableOnInteraction: false}}
    // >
    //     {!load ? getNews() :       
    //     news.slice((news.length - 5),news.length).filter(story => 
    //       ((story.storyTypeId === props.storyTypeId) || !props.storyTypeId)
    //     )
    //     .sort((a,b) => new Date(b.lastExportedDate).getTime() - new Date(a.lastExportedDate).getTime()).map((story) => 
    //         <SwiperSlide>
    //         <div className="news-slide-card" value={story.storyId} onClick={()=>{
                
    //             navigate("/story", {state:{story: story}})
    //         }}>
    //           {story.images &&
    //           <img src={story.images[0].previewUrl} className="news-slide-card-image" alt="" value={story.storyId}/>
    //           }
    //         <div className="news-slide-text" value={story.storyId}>
    //             <div className="news-slide-card-title" value={story.storyId}>
    //                 <h3>{story.headline}</h3>
    //             </div>
    //             {story.images &&
    //             <div className="news-slide-card-desc" value={story.storyId}><p className="news-slide-card-desc-text">{story.storyText.substr(0,130)+"..."}</p></div>
    //             }
    //        </div>
    //       </div>
    //       <br></br>
    //       </SwiperSlide>
    //     )}
    //     </Swiper>
    //   </div>
    // </div>
  );
}
